import React, { type ReactElement, useEffect, useState } from 'react'
import './Welcome.scss'
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader
} from '../sharedModules/modal/Modal'
import Button from '../sharedModules/button/Button'
import { useDispatch, useSelector } from 'react-redux'
import { StatusTypesEnum } from '../../types/CommonTypes'
import { resetViewTransactionStatus } from '../../store/slices/viewTransactionSlice'
import { resetAddTransactionStatus } from '../../store/slices/addTransactionSlice'
import { resetViewPropertyStatus } from '../../store/slices/viewPropertySlice'
import { ReactComponent as SuccessSvg } from '../../assets/Success.svg'
import { ReactComponent as ErrorSvg } from '../../assets/ErrorIcon.svg'
import { type IStatusResponse, resetViewPropertyInventoryStatus } from '../../store/slices/viewPropertyInventorySlice'
import { type AppDispatch } from '../../store/store'
import { resetExportReport, resetReportFieldsModal } from '../../store/slices/reportsSlice'
import { clearResultDataAfterSuccesFail } from '../../store/slices/maintenanceSlice'
import { resetPropertyStatusFlags } from '../../store/slices/addPropertySlice'

interface IMappedType {
  state: IStatusResponse
  resetFn: any
}
interface IProps {
  view?: boolean | false
  navigateFn?: () => void
}

const ResponseModal: React.FC<IProps> = ({ view, navigateFn }): ReactElement | null => {
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [isSucess, setIsSucess] = useState<boolean>(true)
  const [message, setMessage] = useState<string>('')
  const dispatch = useDispatch<AppDispatch>()

  const statuses: IMappedType[] = [
    { state: useSelector((state: any) => state.viewProperty.status), resetFn: resetViewPropertyStatus },
    { state: useSelector((state: any) => state.viewTransaction.status), resetFn: resetViewTransactionStatus },
    { state: useSelector((state: any) => state.addTransaction.status), resetFn: resetAddTransactionStatus },
    // {
    //   state: useSelector((state: any) => view ? state.viewTransaction.status : state.addTransaction.status),
    //   resetFn: view ? resetViewTransactionStatus : resetAddTransactionStatus
    // },
    // { state: useSelector((state: any) => state.viewTransaction.status), resetFn: resetViewTransactionStatus },
    { state: useSelector((state: any) => state.viewPropertyInventory.status), resetFn: resetViewPropertyInventoryStatus },
    { state: useSelector((state: any) => state.report.status), resetFn: resetReportFieldsModal },
    { state: useSelector((state: any) => state.report.exportStatus), resetFn: resetExportReport },
    { state: useSelector((state: any) => state.maintenance.status), resetFn: clearResultDataAfterSuccesFail },
    { state: useSelector((state: any) => state.addProperty.status), resetFn: resetPropertyStatusFlags }
  ]
  let currentItems: IMappedType[] | undefined
  useEffect(() => {
    currentItems =
      statuses.filter((status: IMappedType) => (status.state.type === StatusTypesEnum.SUCCESS ||
        status.state.type === StatusTypesEnum.ERROR) && status.state.message)
    if (currentItems && currentItems.length > 0) {
      openModal(currentItems)
    }
  }, [statuses])

  const openModal = (currentItems: IMappedType[]) => {
    setViewModal(true)
    setMessage(currentItems[0].state.message ? currentItems[0].state.message : '')
    setIsSucess(currentItems[0].state.type === StatusTypesEnum.SUCCESS)
  }

  const onConfirmandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (currentItems) {
      for (const item of currentItems) {
        dispatch(item.resetFn())
      }
      currentItems = undefined
    }
    setViewModal(false)
    if (navigateFn) navigateFn()
  }

  if (!viewModal) { return null }

  return (
    <Modal className="modal">
      <ModalHeader>
        <section className="welcome-header">
          <h4>{isSucess ? 'Success' : 'Error'}</h4>
        </section>
      </ModalHeader>
      <ModalBody>
        <div className="div-modal-body">
          <div className="modal-icon">
            {isSucess ? <SuccessSvg /> : <ErrorSvg />}
          </div>
          <div>
            <p>{message
            }</p>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="modal-footer-container">
          <Button type="confirm" onClick={onConfirmandler}>
            OK
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default ResponseModal

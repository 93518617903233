import React from 'react'
import './Footer.scss'

const Footer = () => {
  const footerText = `© ${new Date().getFullYear()} Southern California Edison`
  return (
    <footer className='reis-footer'>
        <p>{footerText}</p>
    </footer>
  )
}

export default Footer

import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import { type IInventoryInformation } from './viewPropertySlice'
import { StatusTypesEnum } from '../../types/CommonTypes'
import { addProperty } from '../middleWares/addProperty'
// import { addProperty } from '../middleWares/addProperty'

export const inventoryInformationInitialData: IInventoryInformation = {
  propNo: '',
  propertyName: '',
  propId: '',
  propertyAddress: '',
  propertyCounty: '',
  propertyCity: '',
  propertyType: '',
  thomasPage: '',
  thomasGrid: '',
  iso: 0,
  propertyState: '',
  totalAcres: 0,
  zipCode: '',
  totalBookValue: 0,
  comments: '',
  isDeletedSuccessfully: false
}

export const addPropertyInitialData = {
  inventoryInformation: inventoryInformationInitialData,
  status: { type: '', message: '' }
}

const addPropertySlice = createSlice({
  name: 'addProperty',
  initialState: addPropertyInitialData,
  reducers: {

    updateAddPropertyInfoDropdowns (state, action: PayloadAction<any>) {
      const updatedData = { ...state.inventoryInformation, ...action.payload }
      state.inventoryInformation = updatedData
    },

    updatePropertyDetails (state, action: PayloadAction<any>) {
      const updatedData = { ...state.inventoryInformation, ...action.payload }
      state.inventoryInformation = updatedData
    },

    resetPropertyInfo (state) {
      state.inventoryInformation = inventoryInformationInitialData
    },

    resetPropertyStatusFlags (state) {
      state.status = addPropertyInitialData.status
    },

    setPropertyISO (state, action) {
      state.inventoryInformation = { ...state.inventoryInformation, iso: action.payload ? 1 : 0 }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(addProperty.fulfilled, (state, action) => {
      state.inventoryInformation = action.payload.inventoryInfo
      state.status.type = StatusTypesEnum.SUCCESS
      state.status.message = action.payload.message
    })

    builder.addCase(addProperty.rejected, (state, action) => {
      state.status.type = StatusTypesEnum.ERROR
      state.status.message = action.error.message as string
    })

    builder.addCase(addProperty.pending, (state, action) => {
      state.status.type = StatusTypesEnum.LOADING
      state.status.message = addPropertyInitialData.status.message
    })
  }
})

export const {
  updateAddPropertyInfoDropdowns, updatePropertyDetails, setPropertyISO, resetPropertyInfo, resetPropertyStatusFlags
} = addPropertySlice.actions
export default addPropertySlice.reducer

import { createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../config/axios.config'
import { GET_PROPERTIES_ENDPOINT, UNAUTHORISED_MESSAGE } from '../../Constants'

export const updateProperty = createAsyncThunk(
  'property/id',
  async (id: any, thunkAPI): Promise<any> => {
    try {
      const state: any = thunkAPI.getState()
      const inventoryInformation = state.viewProperty.inventoryInformation
      const comments = state.viewProperty.comments
      const responseData = await api.patch(`/${GET_PROPERTIES_ENDPOINT}/${id}`, {
        body: { ...inventoryInformation, comments }
      })
      return responseData.data
    } catch (error: any) {
      if (error.response && error.response.data) {
        const { statusCode } = error.response.data
        if (statusCode === 401) {
          throw new Error(UNAUTHORISED_MESSAGE)
        } else {
          throw error.response.data
        }
      }
    }
  })

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Input from '../../sharedModules/input/Input'
import './InventoryInfo.scss'
import TextArea from '../../sharedModules/textArea/TextArea'
import { NumericFormat } from 'react-number-format'
import { setComments } from '../../../store/slices/viewPropertyInventorySlice'
import { setDecimalPrecision } from '../../../util/setDecimalPrecision'
import { MONEY } from '../../../Constants'

const InventoryInfo: React.FC = () => {
  const dispatch = useDispatch()

  const { inventoryInfo, comments } = useSelector(
    (state: any) => state.viewPropertyInventory
  )

  const amount = inventoryInfo.totalAmount ? setDecimalPrecision(inventoryInfo.totalAmount, 2, MONEY) : '0.00'

  return (
    <section className="inventory-info-container">
      <h2>
        Properties - {inventoryInfo.propertyName} - Acquisition Doc{' '}
        {inventoryInfo.acqDocNo} Property Inventory Accounting Data
      </h2>
      <h2 className="inventory-info-heading">Inventory information</h2>
      <div className="inventory-form-container">
        <section className="property-details">
          <section className="input-block acq-doc-input">
            <label className="input-label">Acq Doc</label>
            <Input
              name="acq-doc-number"
              value={inventoryInfo.acqDocNo ?? ''}
              className="read-input"
              readOnly={true}
            />
          </section>
          <section className="input-block parcel-input">
            <label className="input-label">Parcel</label>
            <Input
              name="parcel"
              value={inventoryInfo.parcelNo ?? ''}
              className="read-input"
              readOnly={true}
            />
          </section>
          <section className="input-block property-id-input">
            <label className="input-label">Property ID</label>
            <Input
              name="property-id"
              value={inventoryInfo.propId ?? ''}
              className="read-input"
              readOnly={true}
            />
          </section>
          <section className="input-block property-name-input">
            <label className="input-label">Property</label>
            <Input
              name="property"
              value={inventoryInfo.propertyName ?? ''}
              className="read-input"
              readOnly={true}
            />
          </section>
        </section>

        <section className="property-amount-details">
          <section className="input-block">
            <label className="input-label">Total Acres</label>
            <NumericFormat
              name="total-acres"
              value={inventoryInfo.totalAcres ?? ''}
              className="input-select-box read-input"
              decimalScale={3}
              fixedDecimalScale
              thousandSeparator
              readOnly={true}
            />
          </section>
          <section className="input-block total-amount-input">
            <label className="input-label">Total Amount</label>
            <NumericFormat
              name="total-amount"
              value={amount}
              className="input-select-box read-input"
              readOnly={true}
              prefix="$"
              thousandSeparator
              decimalScale={2}
              fixedDecimalScale
            />
          </section>
        </section>

        <section className="property-remarks">
          <section className="remarks-txt-area">
            <label className="input-label">Previous Comments</label>
            <TextArea
              readonly={true}
              name="previous-comments"
              value={inventoryInfo.prevComments ?? ''}
              className="read-input"
            />
          </section>
          <section className="remarks-txt-area acq-doc-comments">
            <label className="input-label">Add Acquisition Doc Comments</label>
            <TextArea
              name="comments"
              value={comments ?? ''}
              className="read-input acq-doc-txt"
              placeholder="Enter text" // (up to 200 characters)"
              onChange={(e: any) => dispatch(setComments(e.target.value))}
            />
          </section>
        </section>
      </div>
    </section>
  )
}

export default InventoryInfo

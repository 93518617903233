import { WELCOME_POPUP_LOCAL_STORAGE_KEY } from '../Constants'

export const skipMessageInFuture = (): void => {
  localStorage.setItem(WELCOME_POPUP_LOCAL_STORAGE_KEY, 'true')
}

export const isWelcomePopopSkipped = (): boolean => {
  const isExisting = localStorage.getItem(WELCOME_POPUP_LOCAL_STORAGE_KEY)
  if (isExisting) return true
  return false
}

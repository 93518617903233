import { createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../config/axios.config'
import { DELETE_TRANSACTION, UNAUTHORISED_MESSAGE } from '../../Constants'
import { type ITransactionView } from '../slices/viewTransactionSlice'

const getViewTransactionState = (state: any) => {
  return state.viewTransaction
}

export const deleteTransaction = createAsyncThunk('delete-transaction/post/id',
  async (_, { getState }): Promise<any> => {
    try {
      const trnsactionData: ITransactionView = getViewTransactionState(getState())
      const responseData = await api.delete(`/${DELETE_TRANSACTION}/${trnsactionData.accountingInformation.transId}`,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        })
      return responseData.data
    } catch (error: any) {
      // console.log(JSON.stringify(err))
      const { statusCode } = error.response.data
      if (statusCode === 401) {
        throw new Error(UNAUTHORISED_MESSAGE)
      } else throw error.response.data
    }
  }
)

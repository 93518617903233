import React, {
  type ReactElement,
  useEffect
} from 'react'
import './PropertyActivityData.scss'
import { useDispatch, useSelector } from 'react-redux'
import {
  TRANSACTION_TYPE_SECTION,
  VIEW_TRANSACTION_STATUS,
  VIEW_TRANSACTION_TYPE,
  duplicateComponentActionTypes
} from '../../../../types/CommonTypes'
import PropertyActivityDataSub from './PropertyActivityDataSub'
import Button from '../../../sharedModules/button/Button'
import {
  PROPERTY_ACTIVITY_DATA_ACQUISITION,
  PROPERTY_ACTIVITY_DATA_DISPOSITION
} from '../../../../Constants'
import useDuplicateComponent from '../../../../customHooks/useDuplicateComponent'
import {
  type IAccountingInformation,
  type IAddTransactionType,
  type IPropertyCombinedData
} from '../../../../types/TransactionTypes'
import { addNewIndexToPropertyData } from '../../../../store/slices/addTransactionSlice'
import { type AppDispatch } from '../../../../store/store'
import PropertyDataTotal from './PropertyDataTotal'
import { addNewIndexToPropertyDataView } from '../../../../store/slices/viewTransactionSlice'

interface renderDataType {
  key: number
  component: ReactElement
}

const PropertyActivityData = (props: {
  view?: boolean
  isBookedUpdatable?: boolean
  refresh?: boolean
}): ReactElement => {
  const transactionType: IAddTransactionType = useSelector((state: any) =>
    props.view
      ? state.viewTransaction.transactionType
      : state.addTransaction.transactionType
  )
  const dispatch = useDispatch<AppDispatch>()

  const { updateComponent, renderData } = useDuplicateComponent(
    PropertyActivityDataSub, props.view, props.isBookedUpdatable, props.refresh
  )

  const status: IAccountingInformation = useSelector((state: any) =>
    state.viewTransaction.accountingInformation
  )

  const header =
    transactionType.section === TRANSACTION_TYPE_SECTION.ACQUISITION ||
      (props.view &&
        status.transactionType !== VIEW_TRANSACTION_TYPE.RELINQUISH)
      ? PROPERTY_ACTIVITY_DATA_ACQUISITION
      : PROPERTY_ACTIVITY_DATA_DISPOSITION

  const onAddButtonClickHandler = (): void => {
    if (!props.view) { updateComponent(duplicateComponentActionTypes.ADD) }
    dispatch(props.view ? addNewIndexToPropertyDataView() : addNewIndexToPropertyData())
  }

  const viewTransaction: IPropertyCombinedData[] = useSelector((state: any) =>
    props.view
      ? state.viewTransaction.propertyData.fieldData
      : state.addTransaction.propertyData.fieldData
  )

  const numberOfProperties = viewTransaction.length

  useEffect(() => {
    updateComponent(duplicateComponentActionTypes.RENDER_VIEW, numberOfProperties)
  }, [numberOfProperties])

  useEffect(() => {
    updateComponent(duplicateComponentActionTypes.REFRESH_VIEW, numberOfProperties === 0 ? 1 : numberOfProperties)
  }, [props.isBookedUpdatable])

  useEffect(() => {
    updateComponent(duplicateComponentActionTypes.RENDER_VIEW, numberOfProperties === 0 ? 1 : numberOfProperties)
  }, [props.refresh])

  return (
    <div className="property-activity-data-container">
      <h2 className="sub-heading">{header}</h2>
      {renderData.map((data: renderDataType) => data.component)}
      {(!props.view || status.transactionStatus !== VIEW_TRANSACTION_STATUS.BOOKED || props.isBookedUpdatable) && (
        <div className="add-another-button-container">
          <Button
            buttonType="button"
            className="add-another-button"
            type="confirm"
            onClick={onAddButtonClickHandler}
          >
            +Add Another
          </Button>
        </div>
      )}
      {props.view
        ? (
        <PropertyDataTotal view={true} isBookedUpdatable = {props.isBookedUpdatable}/>
          )
        : (
        <PropertyDataTotal />
          )}

    </div>
  )
}

export default PropertyActivityData

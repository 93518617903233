import { createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../config/axios.config'
import { GET_MAINTENANCE } from '../../Constants'

const getSearchData = (thunkAPI: any) => {
  const state = thunkAPI.getState()
  return state.maintenance
}

export const getMaintenanceSearchResultData = createAsyncThunk(
  'get-maintenance/search-result', async ({ id, defaultParams }:
  { id: string, defaultParams: Record<string, any> }, thunkAPI) => {
    const maintenanaceData = getSearchData(thunkAPI)
    const sort = Object.keys(maintenanaceData.currentlySortedColumnOrder)[0]
    const dir = Object.values(maintenanaceData.currentlySortedColumnOrder)[0]
    try {
      const params = {
        ...defaultParams,
        id,
        sort,
        dir
      }
      const responseData = await api.get(`/${GET_MAINTENANCE}/search/${maintenanaceData.options.id}`, {
        params
      })
      if (!responseData.data) throw new Error('No response data')
      return responseData.data
    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.message) {
        const errorData = error.response.data.message
        throw errorData.response
      }
    }
  })

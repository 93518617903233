import { createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../config/axios.config'
import { AUTOSAVE_ADD_TRANSACTION_ENDPOINT } from '../../Constants'
import { type IErrorData, type IAddTransaction } from '../../types/TransactionTypes'
import formValidation from '../../util/formValidation'

const getTransactionState = (state: any, view?: boolean) => {
  if (view) return state.viewTransaction
  return state.addTransaction
}

const IsAutoSaveRequired = (transactionData: IAddTransaction): boolean => {
  // const { propertyName, propertyNo, propId, transactionType, transactionStatus } = transactionData.accountingInformation
  // const isBookedTransaction = transactionData.isBookedTransaction
  // if (propertyName && propertyNo && propId && transactionType &&
  //       transactionStatus !== VIEW_TRANSACTION_STATUS.BOOKED &&
  //       !isBookedTransaction) { return true }
  const validationResult: IErrorData = formValidation(transactionData, false)
  if (transactionData.isBookedTransaction) return false
  else return !validationResult.isError
}

export const autoSaveTransactionIfNeeded = createAsyncThunk('add/updated-transaction/post',
  async (arg: { view: boolean }, thunkAPI) => {
    const transactionData: IAddTransaction = arg.view
      ? getTransactionState(thunkAPI.getState(), true)
      : getTransactionState(thunkAPI.getState())
    // thunkAPI.dispatch(doValidationOnSave(false))

    if (!IsAutoSaveRequired(transactionData)) return
    try {
      const transId = transactionData.accountingInformation.transId
      const requestEndpoint = transId
        ? `${AUTOSAVE_ADD_TRANSACTION_ENDPOINT}/${transId}`
        : AUTOSAVE_ADD_TRANSACTION_ENDPOINT

      const responseData = await api.post(`/${requestEndpoint}`,
        {
          transactionType: transactionData.transactionType,
          accountingInformation: transactionData.accountingInformation,
          costInformation: transactionData.costInformation,
          crossReference: transactionData.crossReference,
          propertyData: transactionData.propertyData
        }, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
      return responseData.data
    } catch (error: any) {
      throw error?.response?.data
    }
  })

import { StyleSheet } from '@react-pdf/renderer'

export const styles = StyleSheet.create({
  page: { width: '90vh', paddingLeft: 15, paddingRight: 15, paddingTop: 25, paddingBottom: 25 },
  title: {
    textAlign: 'center',
    fontFamily: 'Helvetica',
    fontSize: 16
  },
  accountingInfoTitle: {
    textAlign: 'left',
    fontSize: 12,
    fontFamily: 'Helvetica-Bold',
    marginTop: 8
  },
  subHeading: {
    fontFamily: 'Helvetica',
    marginBottom: 3
  },
  titleBorder: {
    width: '100%',
    borderTop: 0.7,
    borderColor: '#707372',
    marginTop: 8
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 9,
    fontSize: 10,
    fontFamily: 'Helvetica'
  },
  recordNo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 15
  },
  preparedBy: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 23
  },
  recordedDate: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 15
  },
  agent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 20
  },
  status: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 15
  },
  statusDate: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 12
  },
  propertyName: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 38
  },
  propertyId: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 15
  },
  instrumentTitle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 20
  },
  grantor: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 53
  },
  grantee: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 47
  },
  legalDescrption: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 30,
    paddingRight: 5
  },
  grantee1: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 40,
    paddingRight: 5
  },
  titleCompany: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 38
  },
  escrowCompany: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 35
  },
  row7Container: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 12,
    fontSize: 10,
    fontFamily: 'Times-Roman',
    backgroundColor: '#F1F1F1',
    paddingLeft: 2,
    paddingRight: 2,
    paddingBottom: 3,
    paddingTop: 3,
    marginBottom: 10,
    textAlign: 'right'
  },
  val: {
    color: '#757575'
  },
  val1: {
    color: '#757575',
    textAlign: 'right'
  },
  subHeading1: {
    fontFamily: 'Helvetica',
    marginBottom: 3,
    textAlign: 'right'
  },
  recordNo2: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    flex: 18,
    textAlign: 'right'
  },
  agent1: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    flex: 30,
    textAlign: 'right'
  },
  statusDate1: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 7
  }
})

/* eslint-disable no-constant-condition */
/* eslint-disable @typescript-eslint/no-confusing-void-expression */
import axios from 'axios'
import { acquireToken, acquireTokenForGraph } from './tokenConfig'

export const baseURL =
 process.env.REACT_APP_ENVIRONMENT === 'dev'
 //  true
   ? `${process.env.REACT_APP_DEV_BASE_URL}:${process.env.REACT_APP_API_PORT}/api/v${process.env.REACT_APP_API_VERSION}`
   : `${process.env.REACT_APP_PROD_BASE_URL}/api/v${process.env.REACT_APP_API_VERSION}`
const api = axios.create({
  baseURL
})

const graphApi = axios.create({
  baseURL
})

// Add a request inspector
api.interceptors.request.use(
  async (config) => {
    try {
      // use the token utility function to acquire token
      const accessToken = await acquireToken()
      // set access token to request header
      config.headers.Authorization = `Bearer ${accessToken}`
      return config
    } catch (err) {
      console.log(`Error for geting the toke -> ${err}`)
      return await Promise.reject(err)
    }
  },
  async (err) => await Promise.reject(err)
)

// Add a request inspector for graph api
graphApi.interceptors.request.use(
  async (config) => {
    try {
      // use the token utility function to acquire token
      const tokenResponse = await acquireTokenForGraph()
      // set access token t request header
      config.headers.Authorization = `Bearer ${tokenResponse?.accessToken}`
      config.headers['X-ID-TOKEN'] = tokenResponse?.idToken
      return config
    } catch (err) {
      console.log(`Error for geting the toke -> ${err}`)
      return await Promise.reject(err)
    }
  },
  async (err) => await Promise.reject(err)
)
export default api
export const apiGraph = graphApi

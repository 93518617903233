import React, { type ReactElement, useEffect } from 'react'
import './CrossReferenceInformation.scss'
import Button from '../../../sharedModules/button/Button'
import useDuplicateComponent from '../../../../customHooks/useDuplicateComponent'
import CrossReferenceInformationSub from './CrossReferenceInformationSub'
import { useDispatch, useSelector } from 'react-redux'
import { type AppDispatch } from '../../../../store/store'
import { addNewIndexToCrossReferrenceData } from '../../../../store/slices/addTransactionSlice'
import { type IAccountingInformation, type ICrossInformation } from '../../../../types/TransactionTypes'
import { VIEW_TRANSACTION_STATUS, duplicateComponentActionTypes } from '../../../../types/CommonTypes'
import { addNewIndexToCrossReferrenceDataView } from '../../../../store/slices/viewTransactionSlice'

interface renderDataType {
  key: number
  component: ReactElement
}

const CrossReferenceInformation = (props: { view?: boolean | false, isBookedUpdatable?: boolean }): ReactElement => {
  const dispatch = useDispatch<AppDispatch>()

  const { updateComponent, renderData } = useDuplicateComponent(
    CrossReferenceInformationSub, props.view, props.isBookedUpdatable
  )

  const onAddButtonClickHandler = (): void => {
    if (!props.view) { updateComponent(duplicateComponentActionTypes.ADD) }
    dispatch(props.view ? addNewIndexToCrossReferrenceDataView() : addNewIndexToCrossReferrenceData())
  }

  const status: IAccountingInformation = useSelector((state: any) =>
    state.viewTransaction.accountingInformation
  )

  const viewTransaction: ICrossInformation[] = useSelector((state: any) => {
    return state.viewTransaction.crossReference
  })

  const size = viewTransaction.length

  useEffect(() => {
    if (props.view) {
      updateComponent(duplicateComponentActionTypes.RENDER_VIEW, size)
    }
  }, [size])

  useEffect(() => {
    updateComponent(duplicateComponentActionTypes.REFRESH_VIEW, size === 0 ? 1 : size)
  }, [props.isBookedUpdatable])

  return (
    <div className="cross-reference-information-container">
      <h2 className="sub-heading">Cross Reference Information</h2>
      {renderData.map((data: renderDataType) => data.component)}
      {(!props.view || status.transactionStatus !== VIEW_TRANSACTION_STATUS.BOOKED || props.isBookedUpdatable) && (
        <div className="add-another-button-container">
          <Button
            buttonType="button"
            className="add-another-button"
            type="confirm"
            onClick={onAddButtonClickHandler}
          >
            +Add Another
          </Button>
        </div>
      )}
    </div>
  )
}

export default CrossReferenceInformation

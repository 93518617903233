import React, { type ReactElement, useEffect, useState } from 'react'
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader
} from '../../../sharedModules/modal/Modal'
import './AddTransactionModal.scss'
import Button from '../../../sharedModules/button/Button'
import { useDispatch, useSelector } from 'react-redux'
import { postTransaction } from '../../../../store/middleWares/postTransaction'
import { type AppDispatch } from '../../../../store/store'
import { useNavigate } from 'react-router-dom'
import { navigationPath } from '../../../../types/CommonTypes'
import { updateTransaction } from '../../../../store/middleWares/updateTransaction'
import { saveTransaction } from '../../../../store/middleWares/saveTransaction'
import { BOOKED_CONFIRM_MESSAGE, POST_CONFIRM_MESSAGE } from '../../../../Constants'

interface IProps {
  view: boolean | false
  show: boolean | false
  resetShowValue: () => void
}

const TransactionBokedConfirmationModal: React.FC<IProps> = ({ view, show, resetShowValue }): ReactElement | null => {
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()

  const [showModal, setShowModal] = useState<boolean>(false)

  const isPostSubmitted: boolean = useSelector(
    (state: any) => view ? state.viewTransaction.isPostSubmitted : state.addTransaction.isPostSubmitted
  )

  const recordNo = useSelector(
    (state: any) => state.addTransaction.accountingInformation.recordNo
  )

  const transId = useSelector((state: any) => view
    ? state.viewTransaction.accountingInformation.transId
    : state.addTransaction.accountingInformation.transId)

  const isError: boolean = useSelector(
    (state: any) => view ? state.viewTransaction.errorData.isError : state.addTransaction.errorData.isError
  )

  const isStatusPost: boolean = useSelector(
    (state: any) => view ? state.viewTransaction.isStatusPost : state.addTransaction.isStatusPost
  )

  useEffect(() => {
    if (!isError && isPostSubmitted && show) setShowModal(true)
    if (!isError && isStatusPost && show) setShowModal(true)
  }, [isPostSubmitted, isStatusPost, show])

  const onCloseHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    setShowModal(false)
    resetShowValue()
  }

  const onConfirmHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    setShowModal(false)
    if (isPostSubmitted) {
      dispatch(postTransaction(view))
        .unwrap()
        .then(() => {
          if (!view) {
            navigate(navigationPath.VIEW_TRANSACTION + `/${recordNo}`)
          }
        })
        .catch(error => {
          console.log(JSON.stringify(error))
        })
    } else {
      if (isStatusPost) {
        if (transId) dispatch(updateTransaction({ addTransaction: !view }))
        else dispatch(saveTransaction())
      }
    }
    resetShowValue()
  }

  if (!showModal) return null

  return (
    <Modal className="add-transaction-modal">
      <ModalHeader>
        <section className="add-transaction-modal-header">
          <h4>Transaction Information</h4>
        </section>
      </ModalHeader>
      <ModalBody>
        <div
          className="booked-transaction-modal-content">
          <p>{isPostSubmitted ? BOOKED_CONFIRM_MESSAGE : POST_CONFIRM_MESSAGE }</p>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="add-transaction-modal-footer">
          <Button type="cancel" onClick={onCloseHandler}>
            Cancel
          </Button>
          <Button type="confirm" onClick={onConfirmHandler}>
            Continue
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default TransactionBokedConfirmationModal

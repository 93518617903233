/* eslint-disable no-tabs */
import React, { useEffect, useState } from 'react'
import './Maintenance.scss'
import Button from '../../components/sharedModules/button/Button'
import MaintenanceCustomComponent from '../../components/maintenance/maintenanceCustomComponent'
import Select from '../../components/sharedModules/select/Select'
import { MaintenanceOptionsEnum, maintenanceOptionsData } from '../../components/maintenance/maintenanceOptions'
import { clearResultData, setCurrentSortOrder, setIsDeleted, setMaintenanceOption } from '../../store/slices/maintenanceSlice'
import { useDispatch, useSelector } from 'react-redux'
import { type AppDispatch } from '../../store/store'
import { getMaintenanceData } from '../../store/middleWares/getMaintenanceData'
import { deleteMaintenanceCategoryData, updateMaintenanceCategoryData } from '../../store/middleWares/updateMaintainanceData'
import MaintenanceFooter from '../../components/maintenance/customComponents/maintenanceFooter/MaintenanceFooter'
import ResponseModal from '../../components/welcome/ResponseModal'
import { type SORT_DIRECTION } from '../../types/CommonTypes'
import MaintenanceSearch from '../../components/maintenance/customComponents/maintenance-search/MaintenanceSearch'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import { getParamsBasedonURL } from '../../util/getTableParamsBasedonURL'
import useNavigationBasedOnMaintenanceCategory from '../../customHooks/useNavigationBasedOnMaintenanceCategory'
import { getSortedMaintenanceData } from '../../store/middleWares/getSortedMaintenanceData'
import { MAINTENANCE_UPDATE_DELETE_ENUM, RESULT_PER_PAGE_PAGINATION, RESULT_PER_PAGE_PAGINATION_MAINTENANCE } from '../../Constants'
import NotFound404Page from '../common/NotFound404'
import { getMaintenanceDataForExport } from '../../store/middleWares/getMaintenanceDataForExport'
import WarningModal from '../../components/welcome/WarningModal'
import { getMaintenanceSearchResultData } from '../../store/middleWares/getMaintenanceSearchResult'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../components/sharedModules/modal/Modal'
import UnauthorizedWarningPage from '../common/UnauthorizedWarningPage'

const Maintenance = () => {
  const [isSaveButtonActive, setSaveButtonActive] = useState<boolean>(false)
  const [showDeleteWarning, setshowDeleteWarning] = useState<boolean>(false)

  const location = useLocation()
  const { category } = useParams()
  const { navigateTo } = useNavigationBasedOnMaintenanceCategory()
  const [searchParams, setSearchParams] = useSearchParams(location.search)
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false)

  const dispatch = useDispatch<AppDispatch>()

  const isAuthorized = useSelector(
    (state: any) => state.auth.isAuthorized
  )

  const maintenanceOptions: Record<string, MaintenanceOptionsEnum> = useSelector((state: any) => {
    return state.maintenance.options
  })
  const sortColumnOrder: Record<string, SORT_DIRECTION> = useSelector((state: any) => {
    return state.maintenance.currentlySortedColumnOrder
  })
  const { searchToken } = useSelector((state: any) => {
    return state.maintenance
  })
  const deletedIndex = useSelector((state: any) => { return state.maintenance.deletedIndex })
  const isDeleted = useSelector((state: any) => { return state.maintenance.isDeleted })
  const data: any[] = useSelector((state: any) => {
    return state.maintenance.maintenanceData
  })

  useEffect(() => {
    if (isDeleted && !searchToken) getData()
    if (isDeleted && searchToken) handleSearchTokenChange(searchToken)
  }, [isDeleted])

  useEffect(() => {
    if (performance.navigation.type === 1) {
      searchParams.set('page', '1')
      setSearchParams(searchParams)
      searchParams.set('limit', String(RESULT_PER_PAGE_PAGINATION))
    }
    return () => {
      dispatch(clearResultData())
    }
  }, [])

  useEffect(() => {
    const updatedCategory = category as MaintenanceOptionsEnum
    if (category !== maintenanceOptions.id && category) {
      dispatch(setMaintenanceOption({ selectedItem: '', id: updatedCategory }))
    }
  }, [location.pathname, category])

  useEffect(() => {
    getData()
  }, [maintenanceOptions.id, location.search])

  const handleSearchTokenChange = (id: string) => {
    searchParams.set('page', '1')
    // searchParams.set('limit', String(RESULT_PER_PAGE_PAGINATION_MAINTENANCE))
    setSearchParams(searchParams)
    const defaultParams = getParamsBasedonURL(searchParams)
    dispatch(getMaintenanceSearchResultData({ id, defaultParams }))
    dispatch(setIsDeleted(false))
  }
  const getData = () => {
    if ((!category || category === maintenanceOptions.id) && !searchToken) {
      const sort = Object.keys(sortColumnOrder)[0]
      const dir = Object.values(sortColumnOrder)[0]
      searchParams.set('sort', sort)
      searchParams.set('dir', dir)
      setSearchParams(searchParams)
      dispatch(getMaintenanceData({
        selectedCategory: maintenanceOptions.id,
        defaultParams: getParamsBasedonURL(searchParams)
      }))
    }
    dispatch(setIsDeleted(false))
  }
  const doSort = (sortData: Record<string, SORT_DIRECTION>) => {
    const sort = Object.keys(sortData)[0]
    const dir = Object.values(sortData)[0]
    dispatch(setCurrentSortOrder(sortData))
    searchParams.set('sort', sort)
    searchParams.set('dir', dir)
    setSearchParams(searchParams)
    const defaultParams = getParamsBasedonURL(searchParams)
    navigateTo(maintenanceOptions.id, {}, defaultParams)
    dispatch(setCurrentSortOrder(sortData))
    dispatch(getSortedMaintenanceData({ selectedCategory: maintenanceOptions.id, defaultParams }))
  }
  const handleMaintenanceTypeChange = (selectedItem: string, id?: string) => {
    if (!id) return
    const updatedId = id as MaintenanceOptionsEnum
    dispatch(setMaintenanceOption({ selectedItem, id: updatedId }))
    const searchParam = new URLSearchParams(location.search)
    searchParam.set('page', '1')
    setSearchParams(searchParam)
    const newParams = { ...getParamsBasedonURL(searchParam, true) }
    navigateTo(updatedId, {}, newParams)
  }

  const handleFormChange = () => {
    setSaveButtonActive(true)
  }

  const onDeleteHandler = (
    event: React.MouseEvent<SVGSVGElement>
  ) => {
    event.preventDefault()
    setshowDeleteWarning(true)
  }

  const onDeleteConfirm = () => {
    const defaultParams = getParamsBasedonURL(searchParams)
    dispatch(deleteMaintenanceCategoryData({ selectedCategory: maintenanceOptions.id, deletedIndex, defaultParams }))
    setshowDeleteWarning(false)
    // handleFormChange()
  }

  const onDeleteModalCancelHandler = () => {
    setshowDeleteWarning(false)
  }
  const onSave = () => {
    if (!isSaveButtonActive) return
    setConfirmationModal(true)
  }

  const handleExport = () => {
    dispatch(getMaintenanceDataForExport(
      maintenanceOptions.id
    ))
  }

  const onModalCancelHandler = () => {
    setConfirmationModal(false)
  }

  const onConfirmHandler = () => {
    setConfirmationModal(false)
    const sort = Object.keys(sortColumnOrder)[0]
    const dir = Object.values(sortColumnOrder)[0]
    searchParams.set('sort', sort)
    searchParams.set('dir', dir)
    setSearchParams(searchParams)
    const defaultParams = getParamsBasedonURL(searchParams)
    dispatch(updateMaintenanceCategoryData({ selectedCategory: maintenanceOptions.id, defaultParams }))
    setSaveButtonActive(false)
  }

  return (
    <>
    {
      isAuthorized
        ? <div>
      {confirmationModal && <Modal className='maintenance-confirm-modal'>
        <ModalHeader>Update Maintenance</ModalHeader>
        <ModalBody>Do you want to save the changes?</ModalBody>
        <ModalFooter>
          <div className="button-container">
            <Button type="cancel" onClick={onModalCancelHandler}>
              Cancel
            </Button>
            <Button type="confirm" onClick={onConfirmHandler}>
              Save
            </Button>
          </div>
        </ModalFooter>
      </Modal>}
      <ResponseModal />
      { showDeleteWarning && <WarningModal warningLabel='Delete' modalAction='Delete'
      view ={showDeleteWarning} txnOpertionEnum= {MAINTENANCE_UPDATE_DELETE_ENUM.DELETE}
      cancelFn={onDeleteModalCancelHandler} confirmFn={onDeleteConfirm}/>}

      <div className="maintance-container">
        <div className='heading-container'>
          <div className='column-div'>
            <h4 className='heading-maintenance'>Maintenance</h4>
            <label className='header-label-text'>Select a maintenance item to edit and then save to retain any changes.</label>
          </div>
          <div className='save-and-export-btn'>
            <Button type={'confirm'}
              className={isSaveButtonActive ? 'save-create-pdf-button' : 'print-button'}
              onClick={onSave}
            >
              Save
            </Button>
            {(maintenanceOptions.id === MaintenanceOptionsEnum.PLANT_LOCATION ||
              maintenanceOptions.id === MaintenanceOptionsEnum.FERC_ACCT ||
              maintenanceOptions.id === MaintenanceOptionsEnum.GL_ACCOUNT) && <Button type="confirm" className='export-btn' onClick={handleExport}>Export</Button>}
          </div>
        </div>
        <div className='select-div-container'>
          <section>
            <label className='label'>Maintenance Category</label>
            <div className='select-container'>
              <Select
                className='select'
                name='maintenance-options'
                value={maintenanceOptions.selectedItem}
                data={maintenanceOptionsData}
                onChange={handleMaintenanceTypeChange}
                placeholder='Select a category'
                listClassName='maintenance-options-list'
              />
            </div>
          </section>
          <MaintenanceSearch onClearAction={getData} selectedCategory={maintenanceOptions.id} onInputChange= {handleSearchTokenChange}/>
        </div>
      </div>
      <div className='data-container'>
        {data?.length === 0 ? <NotFound404Page /> : <MaintenanceCustomComponent onChange={handleFormChange} sort={doSort} onDelete={onDeleteHandler} categoryData= {data}/>}
      </div>
      <MaintenanceFooter params={{ selectedCategory: maintenanceOptions.id, limit: String(RESULT_PER_PAGE_PAGINATION_MAINTENANCE), sortColumnOrder, page: searchParams.get('page') }} />
    </div>
        : <UnauthorizedWarningPage />
    }
    </>
  )
}

export default Maintenance

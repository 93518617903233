import React, { useEffect } from 'react'
import Input from '../../../sharedModules/input/Input'
import Select from '../../../sharedModules/select/Select'
import TextArea from '../../../sharedModules/textArea/TextArea'
import CheckBox from '../../../sharedModules/checkbox/CheckBox'
import './AccountingInformation.scss'
import { useDispatch, useSelector } from 'react-redux'
import { type IAccountingInformation, type IErrorData } from '../../../../types/TransactionTypes'
import { setAccountingInformationData, updateErrorDescription } from '../../../../store/slices/addTransactionSlice'
import { getDropdownData } from '../../../../util/getDropDownPropData'
import { getRecordNo } from '../../../../store/middleWares/getRecordNo'
import { type AppDispatch } from '../../../../store/store'
import { getDropdownFilteredValues } from '../../../../store/middleWares/getDropdownValues'
import { DropDownFiledsEnum } from '../../../../types/CommonTypes'
import { DROPDOWN_API_CALL_DELAY, ERROR_TEXTS } from '../../../../Constants'
import useDebounce from '../../../../customHooks/useDebounce'
import getCurrentDate from '../../../../util/getCurrentDate'
import { replaceNonDigit } from '../../../../util/sanitizeString'
import { setAccountingInformationDataView, updateErrorDescriptionView } from '../../../../store/slices/viewTransactionSlice'
import { getPropertyIdFromProperty } from '../../../../store/middleWares/getPropertyIdFromProperty'
import useGetLoggedInUser from '../../../../customHooks/useGetLoggedInUser'
import { countyMapping } from '../../../../util/countyApnMapping'
import { getApnsError, validateApns } from '../../../../util/validateApn'

const AccountingInformation = (props: {
  view?: boolean
  isBookedUpdatable?: boolean
}) => {
  const fieldData: IAccountingInformation = useSelector((state: any) =>
    props.view
      ? state.viewTransaction.accountingInformation
      : state.addTransaction.accountingInformation
  )

  const isBookedTransaction: boolean | false = useSelector((state: any) => {
    return props.view
      ? state.viewTransaction.isBookedTransaction
      : state.addTransaction.isBookedTransaction
  })

  const dispatch = useDispatch<AppDispatch>()
  const transactionStatus = useSelector((state: any) => state.dropDown.transactionStatus)
  const agent = useSelector((state: any) => state.dropDown.agent)
  const property = useSelector((state: any) => state.dropDown.property)
  const instrumentTitle = useSelector((state: any) => state.dropDown.instrumentTitle)
  const documentType = useSelector((state: any) => state.dropDown.transactionDocType)
  const transactionType = useSelector((state: any) => state.dropDown.transactionType)
  const county = useSelector((state: any) => state.dropDown.county)
  const organization = useSelector((state: any) => state.dropDown.organization)
  const titleCompany = useSelector((state: any) => state.dropDown.titleCompany)
  const escrowCompany = useSelector((state: any) => state.dropDown.escrowCompany)
  const loggedInUserName = useGetLoggedInUser()
  const { errorDesc }: IErrorData = useSelector(
    (state: any) => {
      return props.view ? state.viewTransaction.errorData : state.addTransaction.errorData
    }
  )

  useEffect(() => {
    if (props.view) return
    dispatch(getRecordNo())
    onFieldValueChangeHandler({ preparedBy: loggedInUserName })
  }, [])

  const getPropertyIdFromPropertyName = (id: string) => {
    if (!id) {
      onFieldValueChangeHandler({ propId: '' })
      return
    }
    // eslint-disable-next-line eqeqeq
    const propertyNo = property.find((pr: any) => pr.id == id)?.id
    dispatch(getPropertyIdFromProperty(propertyNo))
  }

  const onFieldValueChangeHandler = (data: any) => {
    const fieldName = Object.keys(data)[0]
    const errData = { ...errorDesc }
    errData[fieldName] = ''

    // if doc number
    if (data.documentNo) {
      const convertedStr = replaceNonDigit(data.documentNo)
      data.documentNo = convertedStr.substring(0, 6)
    }

    if (data.apn) data.apn = data.apn.substring(0, 500) // VARCHAR(500) in database
    if (fieldName === 'apn' && fieldData.county) {
      const format: string = countyMapping(fieldData)
      const apnError: string | undefined = getApnsError(data.apn, format)
      if (apnError) {
        errData[fieldName] = { default: apnError }
      } else {
        data.apn = validateApns(data.apn, format)
      }
    }
    if (fieldName === 'county') { // Reset APN error if county changes
      data.apn = ''
      errData.apn = ''
    }

    if (data.serialNumber) data.serialNumber = data.serialNumber.substring(0, 50)
    if (data.workOrderNumber) data.workOrderNumber = data.workOrderNumber.substring(0, 50)
    if (data.preparedBy) data.preparedBy = data.preparedBy.substring(0, 50)
    if (data.recordingInstrumentNo) data.recordingInstrumentNo = data.recordingInstrumentNo.substring(0, 50)
    if (data.rpFileNo) data.rpFileNo = data.rpFileNo.substring(0, 50)
    // if (data.legalDescription) data.legalDescription = data.legalDescription.substring(0, 200)
    // if (data.comments) data.comments = data.comments.substring(0, 200)
    if (data.saleNumber) data.saleNumber = data.saleNumber.substring(0, 50)
    if (data.purchaseNumber) data.purchaseNumber = data.purchaseNumber.substring(0, 50)
    if (data.thomasPage) data.thomasPage = data.thomasPage.substring(0, 50)
    if (data.thomasGrid) data.thomasGrid = data.thomasGrid.substring(0, 50)
    if (data.agent) data.agent = data.agent.substring(0, 25)
    if (data.grantor) data.grantor = data.grantor.substring(0, 100)
    if (data.grantee) data.grantee = data.grantee.substring(0, 100)
    dispatch(
      props.view
        ? updateErrorDescriptionView(errData)
        : updateErrorDescription(errData)
    )
    dispatch(
      props.view
        ? setAccountingInformationDataView(data)
        : setAccountingInformationData(data)
    )
  }

  const getDropdownValuesWithoutDelay = (field: string, value: string) => {
    dispatch(getDropdownFilteredValues({ field, token: value }))
  }

  const debounceAPICall = useDebounce((field: string, value: string) => {
    dispatch(getDropdownFilteredValues({ field, token: value }))
  }, DROPDOWN_API_CALL_DELAY)

  const onDropdownValueChange = (data: any, field: string) => {
    let addTransDocTypeChange: string = ''
    if (field === 'trType' && data.transactionType === 'Amendment') {
      addTransDocTypeChange = 'Attached'
    } else if (field === 'trType' && data.transactionType === 'Acquisition') {
      addTransDocTypeChange = 'New'
    }
    if (addTransDocTypeChange && !props.view) {
      data.documentType = addTransDocTypeChange
    }
    onFieldValueChangeHandler(data)
    const value: string = Object.values(data)[0] as string
    debounceAPICall(field, value)
  }

  const setErrorStyleAndMessage = (fieldName: string) => {
    const StyleAndText = {
      errorStyle: '',
      errorText: ''
    }
    if (errorDesc && errorDesc[fieldName]) {
      StyleAndText.errorStyle = 'error-input'
      StyleAndText.errorText = errorDesc[fieldName]?.default ?? ERROR_TEXTS.REQUIRED_LABEL
    }
    if (isBooked()) {
      StyleAndText.errorStyle = 'read-input'
    }
    return StyleAndText
  }

  const isBooked = () => {
    return props.view && isBookedTransaction && !props.isBookedUpdatable
    // fieldData.transactionStatus === VIEW_TRANSACTION_STATUS.BOOKED;
  }

  return (
    <div className="accounting-information-container">
      <h2 className="sub-heading">Accounting Information</h2>
      <div className="sub-section">
        <section>
          <label className="input-label">Record Number</label>
          <Input
            name="record-number"
            value={fieldData.recordNo ?? ''}
            onChange={(e) => {
              onFieldValueChangeHandler({ recordNo: Number(e.target.value) })
            }}
            inputType="number"
            className={setErrorStyleAndMessage('recordNo').errorStyle}
            placeholder={isBooked() ? '' : '########'}
            readOnly={true} // TBD:- makes record number is read only to avoid data issues
          />
        </section>
        <section>
          <label className="input-label">Prepared By</label>
          <Input
            name="prepared-by"
            placeholder={isBooked() ? '' : 'Prepared by'}
            value={fieldData.preparedBy}
            onChange={(e) => { onFieldValueChangeHandler({ preparedBy: e.target.value }) }
            }
            className={setErrorStyleAndMessage('preparedBy').errorStyle}
            errorDesc={setErrorStyleAndMessage('preparedBy').errorText}
            readOnly={isBooked()}
          />
        </section>
        <section>
          <label className="input-label">Prepared Date</label>
          <Input
            readOnly={isBooked()}
            name="prepared-date"
            inputType="date"
            placeholder={isBooked() ? '' : 'XX/XX/XXXX'}
            value={fieldData.preparedDate ?? ''}
            onChange={(e) => {
              onFieldValueChangeHandler({ preparedDate: e.target.value })
            }}
            max={getCurrentDate()}
            className={setErrorStyleAndMessage('preparedDate').errorStyle}
            errorDesc={setErrorStyleAndMessage('preparedDate').errorText}
          />
        </section>

        <section className="dropdown-section">
          <label className="input-label">Status</label>
          <Select
            key="status"
            name="status"
            data={getDropdownData(transactionStatus)}
            value={fieldData.transactionStatus ?? ''}
            onChange={(selectedItem: string) => {
              onDropdownValueChange(
                { transactionStatus: selectedItem },
                DropDownFiledsEnum.TRANSACTION_STATUS
              )
            }}
            triggerOnFocus={(value: string) => { getDropdownValuesWithoutDelay(DropDownFiledsEnum.TRANSACTION_STATUS, value) }}
            placeholder={isBooked() ? '' : 'Select an option'}
            className={setErrorStyleAndMessage('status').errorStyle}
            errorDesc={setErrorStyleAndMessage('status').errorText}
            readonly={props.view && isBookedTransaction}
          />
        </section>
        <section>
          <label className="input-label">Status Date</label>
          <Input
            name="status-date"
            inputType="date"
            placeholder={isBooked() ? '' : 'XX/XX/XXXX'}
            value={String(fieldData.statusDate ?? '')}
            onChange={(e) => {
              onFieldValueChangeHandler({ statusDate: e.target.value })
            }}
            max={getCurrentDate()}
            className={setErrorStyleAndMessage('statusDate').errorStyle}
            errorDesc={setErrorStyleAndMessage('statusDate').errorText}
            readOnly={isBooked()}
          />
        </section>
        <section className="dropdown-section">
          <label className="input-label">Agent</label>
          <Select
            key="agent"
            name="agent"
            data={getDropdownData(agent)}
            value={fieldData.agent ?? ''}
            ignoreValidation = {true}
            onChange={(selectedItem: string) => {
              onDropdownValueChange(
                { agent: selectedItem },
                DropDownFiledsEnum.AGENT
              )
            }}
            triggerOnFocus={(value: string) => { getDropdownValuesWithoutDelay(DropDownFiledsEnum.AGENT, value) }}
            className={setErrorStyleAndMessage('agent').errorStyle}
            errorDesc={setErrorStyleAndMessage('agent').errorText}
            readonly={isBooked()}
            placeholder={isBooked() ? '' : 'Select an option'}
          />
        </section>

        <section className="dropdown-section">
          <label className="input-label">Property</label>
          <Select
            key="property"
            name="property"
            data={getDropdownData(property)}
            value={fieldData.propertyName ?? ''}
            onChange={(selectedItem: string, id?: string) => {
              onDropdownValueChange(
                { propertyName: selectedItem, propertyNo: id },
                DropDownFiledsEnum.PROPERTY
              )
              getPropertyIdFromPropertyName(id ?? '')
            }}
            triggerOnFocus={(value: string) => { getDropdownValuesWithoutDelay(DropDownFiledsEnum.PROPERTY, value) }}
            className={setErrorStyleAndMessage('propertyName').errorStyle}
            errorDesc={setErrorStyleAndMessage('propertyName').errorText}
            placeholder={isBooked() ? '' : 'Select an option'}
            readonly={isBooked()}
          />
        </section>
        <section>
          <label className="input-label">Property ID</label>
          <Input
            name="property-id"
            placeholder={isBooked() ? '' : '#####'}
            value={fieldData.propId ?? ''}
            onChange={(e) => {
              onFieldValueChangeHandler({ propId: e.target.value })
            }}
            readOnly={isBooked()}
            className={isBooked() ? 'read-input' : ''}
          />
        </section>
        <section className="dropdown-section">
          <label className="input-label">Instrument Title</label>
          <Select
            key="instrument-title"
            name="instrument title"
            data={getDropdownData(instrumentTitle)}
            value={fieldData.instrumentTitle ?? ''}
            onChange={(selectedItem: string) => {
              onDropdownValueChange(
                { instrumentTitle: selectedItem },
                DropDownFiledsEnum.INSTRUMENT_TITLE
              )
            }}
            triggerOnFocus={(value: string) => { getDropdownValuesWithoutDelay(DropDownFiledsEnum.INSTRUMENT_TITLE, value) }}
            className={setErrorStyleAndMessage('instrumentTitle').errorStyle}
            errorDesc={setErrorStyleAndMessage('instrumentTitle').errorText}
            readonly={isBooked()}
            placeholder={isBooked() ? '' : 'Select an option'}
          />
        </section>

        <section>
          <label className="input-label">Serial Number</label>
          <Input
            name="serial-number"
            placeholder={isBooked() ? '' : '####'}
            value={fieldData.serialNumber ?? ''}
            onChange={(e) => {
              onFieldValueChangeHandler({ serialNumber: e.target.value })
            }}
            className={setErrorStyleAndMessage('serialNumber').errorStyle}
            errorDesc={setErrorStyleAndMessage('serialNumber').errorText}
            readOnly={isBooked()}
          />
        </section>
        <section>
          <label className="input-label">Document Number</label>
          <Input
            name="document-number"
            placeholder={isBooked() ? '' : '#####'}
            inputType="string"
            value={fieldData.documentNo ?? ''}
            onChange={(e) => {
              onFieldValueChangeHandler({ documentNo: e.target.value })
            }}
            className={setErrorStyleAndMessage('documentNo').errorStyle}
            errorDesc={setErrorStyleAndMessage('documentNo').errorText}
            readOnly={isBooked()}
          />
        </section>
        <section>
          <label className="input-label">Execution Date</label>
          <Input
            name="execution-date"
            inputType="date"
            placeholder={isBooked() ? '' : 'XX/XX/XXXX'}
            value={String(fieldData.executionDate ?? '')}
            onChange={(e) => {
              onFieldValueChangeHandler({ executionDate: e.target.value })
            }}
            max={getCurrentDate()}
            className={setErrorStyleAndMessage('executionDate').errorStyle}
            errorDesc={setErrorStyleAndMessage('executionDate').errorText}
            readOnly={isBooked()}
          />
        </section>

        <section className="dropdown-section">
          <label className="input-label">Document Type</label>
          <Select
            key="doc-type"
            name="document-type"
            data={getDropdownData(documentType)}
            value={fieldData.documentType ?? ''}
            onChange={(selectedItem: string) => {
              onDropdownValueChange(
                { documentType: selectedItem },
                DropDownFiledsEnum.TRANSACTION_DOC_TYPE
              )
            }}
            triggerOnFocus={(value: string) => { getDropdownValuesWithoutDelay(DropDownFiledsEnum.TRANSACTION_DOC_TYPE, value) }}
            readonly={isBooked()}
            placeholder={isBooked() ? '' : 'Select an option'}
            // className={isBooked() ? 'read-input' : ''}
            className={setErrorStyleAndMessage('documentType').errorStyle}
            errorDesc={setErrorStyleAndMessage('documentType').errorText}

          />
        </section>
        <section className="dropdown-section">
          <label className="input-label">Transaction Type</label>
          <Select
            key="transaction-type"
            name="transaction-type"
            data={getDropdownData(transactionType)}
            value={fieldData.transactionType ?? ''}
            onChange={(selectedItem: string) => {
              onDropdownValueChange(
                { transactionType: selectedItem },
                DropDownFiledsEnum.TRANSACTION_TYPE
              )
            }}
            triggerOnFocus={(value: string) => { getDropdownValuesWithoutDelay(DropDownFiledsEnum.TRANSACTION_TYPE, value) }}
            className={setErrorStyleAndMessage('transactionType').errorStyle}
            errorDesc={setErrorStyleAndMessage('transactionType').errorText}
            placeholder={isBooked() ? '' : 'Select an option'}
            readonly={props.view}
          />
        </section>
        <section>
          <label className="input-label">Recorded Date</label>
          <Input
            name="recorded-date"
            inputType="date"
            placeholder={isBooked() ? '' : 'XX/XX/XXXX'}
            value={String(fieldData.recordedDate ?? '')}
            onChange={(e) => {
              onFieldValueChangeHandler({ recordedDate: e.target.value })
            }}
            max={getCurrentDate()}
            className={setErrorStyleAndMessage('recordedDate').errorStyle}
            errorDesc={setErrorStyleAndMessage('recordedDate').errorText}
            readOnly={isBooked()}
          />
        </section>

        <section>
          <label className="input-label">Grantor</label>
          <Input
            name="grantor"
            placeholder={isBooked() ? '' : 'Enter Grantor Name'}
            value={fieldData.grantor ?? ''}
            onChange={(e) => {
              onFieldValueChangeHandler({ grantor: e.target.value })
            }}
            className={setErrorStyleAndMessage('grantor').errorStyle}
            errorDesc={setErrorStyleAndMessage('grantor').errorText}
            readOnly={isBooked()}
          />
        </section>
        <section>
          <label className="input-label">Grantee</label>
          <Input
            name="grantee"
            placeholder={isBooked() ? '' : 'Enter Grantee Name'}
            value={fieldData.grantee ?? ''}
            onChange={(e) => {
              onFieldValueChangeHandler({ grantee: e.target.value })
            }}
            className={setErrorStyleAndMessage('grantee').errorStyle}
            errorDesc={setErrorStyleAndMessage('grantee').errorText}
            readOnly={isBooked()}
          />
        </section>
        <section>
          <label className="input-label">
            Recording Instrument Number(RIN)
          </label>
          <Input
            name="recording-instrument-number"
            placeholder={isBooked() ? '' : '#####'}
            value={fieldData.recordingInstrumentNo ?? ''}
            onChange={(e) => {
              onFieldValueChangeHandler({
                recordingInstrumentNo: e.target.value
              })
            }}
            className={
              setErrorStyleAndMessage('recordingInstrumentNo').errorStyle
            }
            errorDesc={
              setErrorStyleAndMessage('recordingInstrumentNo').errorText
            }
            readOnly={isBooked()}
          />
        </section>

        <section className="dropdown-section">
          <label className="input-label">County</label>
          <Select
            key="county"
            name="county"
            data={getDropdownData(county)}
            value={fieldData.county ?? ''}
            onChange={(selectedItem: string) => {
              onDropdownValueChange(
                { county: selectedItem },
                DropDownFiledsEnum.COUNTY
              )
            }}
            triggerOnFocus={(value: string) => { getDropdownValuesWithoutDelay(DropDownFiledsEnum.COUNTY, value) }}
            className={setErrorStyleAndMessage('county').errorStyle}
            errorDesc={setErrorStyleAndMessage('county').errorText}
            readonly={isBooked()}
            placeholder={isBooked() ? '' : 'Select an option'}
          />
        </section>
        <section className="textarea-section1 comments-textarea">
          <label className="input-label">Assessor Parcel Number(s) (APN)</label>
          <TextArea
            name="assessor-parcel-number"
            placeholder={isBooked() ? '' : countyMapping(fieldData)}
            value={fieldData.apn ?? ''}
            onChange={(e) => {
              onFieldValueChangeHandler({ apn: e.target.value })
            }}
            readonly={isBooked()}
            // className={isBooked() ? 'read-input' : ''}
            className={'fixed ' + setErrorStyleAndMessage('apn').errorStyle}
            errorDesc={setErrorStyleAndMessage('apn').errorText}

          />
        </section>
        <section className="dropdown-section">
          <label className="input-label">Organization</label>
          <Select
            key="organization"
            name="organization"
            data={getDropdownData(organization)}
            value={fieldData.organization ?? ''}
            onChange={(selectedItem: string) => {
              onDropdownValueChange(
                { organization: selectedItem },
                DropDownFiledsEnum.ORGANIZATION
              )
            }}
            triggerOnFocus={(value: string) => { getDropdownValuesWithoutDelay(DropDownFiledsEnum.ORGANIZATION, value) }}
            readonly={isBooked()}
            className={isBooked() ? 'read-input' : ''}
          />
        </section>

        <section>
          <label className="input-label">Notification Number</label>
          <Input
            name="notification-number"
            placeholder={isBooked() ? '' : '######'}
            value={fieldData.rpFileNo ?? ''}
            onChange={(e) => {
              onFieldValueChangeHandler({ rpFileNo: e.target.value })
            }}
            readOnly={isBooked()}
            className={isBooked() ? 'read-input' : ''}
          />
        </section>
        <section>
          <label className="input-label">Work Order Number</label>
          <Input
            name="work-order-number"
            placeholder={isBooked() ? '' : '######'}
            value={fieldData.workOrderNumber ?? ''}
            onChange={(e) => {
              onFieldValueChangeHandler({ workOrderNumber: e.target.value })
            }}
            className={setErrorStyleAndMessage('workOrderNumber').errorStyle}
            errorDesc={setErrorStyleAndMessage('workOrderNumber').errorText}
            readOnly={isBooked()}
          />
        </section>
        <section>
          <label className="input-label">Work Order Closed Date</label>
          <Input
            name="work-order-closed-date"
            inputType="date"
            placeholder={isBooked() ? '' : 'XX/XX/XXXX'}
            value={String(fieldData.workOrderClosedDate ?? '')}
            onChange={(e) => {
              onFieldValueChangeHandler({
                workOrderClosedDate: e.target.value
              })
            }}
            max={getCurrentDate()}
            // className={
            //   errorDesc && errorDesc.workOrderClosedDate
            //     ? 'error-input'
            //     : isBooked()
            //       ? 'read-input'
            //       : ''
            // }
            // errorDesc={errorDesc && errorDesc.workOrderClosedDate}
            className={setErrorStyleAndMessage('workOrderClosedDate').errorStyle}
            errorDesc={setErrorStyleAndMessage('workOrderClosedDate').errorText}

            readOnly={isBooked()}
          />
        </section>

        <section className="textarea-section">
          <label className="input-label">Legal Description</label>
          <TextArea
            name="legal-description"
            className={
              isBooked()
                ? 'read-input legal-description-textarea'
                : 'legal-description-textarea'
            }
            placeholder={isBooked() ? '' : 'Enter Text'} // (upto 200 characters)'}
            value={fieldData.legalDescription ?? ''}
            onChange={(e) => {
              onFieldValueChangeHandler({ legalDescription: e.target.value })
            }}
            readonly={isBooked()}
          />
        </section>
        <section className="textarea-section">
          <label className="input-label">Comments</label>
          <TextArea
            name="comments"
            className={
              isBooked() ? 'read-input comments-textarea' : 'comments-textarea'
            }
            placeholder={isBooked() ? '' : 'Enter Text'} // (upto 200 characters)'}
            value={fieldData.comments ?? ''}
            onChange={(e) => {
              onFieldValueChangeHandler({ comments: e.target.value })
            }}
            readonly={isBooked()}
          />
        </section>

        <div className="divided-section">
          <div className="divided-left-section">
            <section className="dropdown-section">
              <label className="input-label">Title Company</label>
              <Select
                key="title-company"
                name="title-company"
                data={getDropdownData(titleCompany)}
                value={fieldData.titleCompany ?? ''}
                onChange={(selectedItem: string) => {
                  onDropdownValueChange(
                    { titleCompany: selectedItem },
                    DropDownFiledsEnum.TITLE_COMPANY
                  )
                }}
                triggerOnFocus={(value: string) => { getDropdownValuesWithoutDelay(DropDownFiledsEnum.TITLE_COMPANY, value) }}
                readonly={isBooked()}
                placeholder={isBooked() ? '' : 'Select an option'}
                className={isBooked() ? 'read-input' : ''}
              />
            </section>
            <section className="dropdown-section">
              <label className="input-label">Escrow Company</label>
              <Select
                key="escrow-company"
                name="escrow-company"
                value={fieldData.escrowCompany ?? ''}
                data={getDropdownData(escrowCompany)}
                onChange={(selectedItem: string) => {
                  onDropdownValueChange(
                    { escrowCompany: selectedItem },
                    DropDownFiledsEnum.ESCROW_COMPANY
                  )
                }}
                triggerOnFocus={(value: string) => { getDropdownValuesWithoutDelay(DropDownFiledsEnum.ESCROW_COMPANY, value) }}
                readonly={isBooked()}
                placeholder={isBooked() ? '' : 'Select an option'}
                className={isBooked() ? 'read-input' : ''}
              />
            </section>

            <section>
              <label className="input-label">Purchase Number</label>
              <Input
                name="purchase-number"
                placeholder={isBooked() ? '' : '#####'}
                value={fieldData.purchaseNumber ?? ''}
                onChange={(e) => {
                  onFieldValueChangeHandler({ purchaseNumber: e.target.value })
                }}
                readOnly={isBooked()}
                className={isBooked() ? 'read-input' : ''}
              />
            </section>
            <section>
              <label className="input-label">Sale Number</label>
              <Input
                name="sale-number"
                placeholder={isBooked() ? '' : '####'}
                value={fieldData.saleNumber ?? ''}
                onChange={(e) => {
                  onFieldValueChangeHandler({ saleNumber: e.target.value })
                }}
                readOnly={isBooked()}
                className={isBooked() ? 'read-input' : ''}
              />
            </section>
            <section>
              <label className="input-label">Thomas Page/Grid </label>
              <span className="thomas-page-grid-section">
                <Input
                  name="thomas-page"
                  placeholder={isBooked() ? '' : '###'}
                  value={fieldData.thomasPage ?? ''}
                  onChange={(e) => {
                    onFieldValueChangeHandler({ thomasPage: e.target.value })
                  }}
                  readOnly={isBooked()}
                  className={isBooked() ? 'read-input' : ''}
                />
                <Input
                  name="thomas-grid"
                  placeholder={isBooked() ? '' : '###'}
                  value={fieldData.thomasGrid ?? ''}
                  onChange={(e) => {
                    onFieldValueChangeHandler({ thomasGrid: e.target.value })
                  }}
                  readOnly={isBooked()}
                  className={isBooked() ? 'read-input' : ''}
                />
              </span>
            </section>
          </div>

          <div className="divided-right-section">
            <label className="select-if-applicable">Select If Applicable</label>
            <CheckBox
              id="structures"
              name="structures"
              value="structures"
              onChange={(e: any) => {
                onFieldValueChangeHandler({ structures: e.target.checked })
              }}
              checked={fieldData.structures}
              label="Structures"
              readonly={isBooked()}
              disabled={isBooked()}
              className={isBooked() ? 'read-checkbox' : ''}
            />
            <CheckBox
              id="easementresrv"
              name="easementresrv"
              value="easementresrv"
              onChange={(e) => {
                onFieldValueChangeHandler({ easementReserv: e.target.checked })
              }}
              checked={fieldData.easementReserv}
              label="Easement Resrv"
              readonly={isBooked()}
              disabled={isBooked()}
              className={isBooked() ? 'read-checkbox' : ''}
            />
            <CheckBox
              id="environmentalimpact"
              name="environmentalimpact"
              value="environmentalimpact"
              onChange={(e) => {
                onFieldValueChangeHandler({ envImpact: e.target.checked })
              }}
              checked={fieldData.envImpact}
              label="Environmental Impact"
              readonly={isBooked()}
              disabled={isBooked()}
              className={isBooked() ? 'read-checkbox' : ''}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountingInformation

import { createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../config/axios.config'
import { SEARCH } from '../../Constants'
import { type ISearch } from '../slices/searchSlice'

const getSearchData = (thunkAPI: any): ISearch => {
  const state = thunkAPI.getState()
  return state.search
}

export const getSearchResult = createAsyncThunk('search/get', async (_, thunkAPI) => {
  const searchData = getSearchData(thunkAPI)
  const responseData = await api.get(`${SEARCH}/${searchData.searchType.id}?token=${searchData.searchToken}`)
  return responseData.data
})

import { createAsyncThunk } from '@reduxjs/toolkit'
import { type MaintenanceOptionsEnum } from '../../components/maintenance/maintenanceOptions'
import api from '../../config/axios.config'
import { GET_MAINTENANCE } from '../../Constants'

export const getMaintenanceData = createAsyncThunk(
  'get-maintenance', async ({ selectedCategory, defaultParams }:
  { selectedCategory: MaintenanceOptionsEnum, defaultParams: Record<string, any> }, { rejectWithValue, dispatch }) => {
    try {
      const params = defaultParams
      const responseData = await api.get(`/${GET_MAINTENANCE}/${selectedCategory}`, {
        params
      })
      if (!responseData.data) throw new Error('No response data')
      return responseData.data
    } catch (error: any) {
      if (error.response && error.response.data) {
        const { statusCode } = error.response.data
        if (statusCode === 401) {
          return rejectWithValue(error.response.data)
        } else {
          throw error.response.data
        }
      }
    }
  })

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './InventoryAcctDataSub.scss'
import Input from '../../sharedModules/input/Input'
import { type InventoryAccountingDataType, updateInventoryAccountingData, updateErrorDescription } from '../../../store/slices/viewPropertyInventorySlice'
import { ReactComponent as DeleteSVG } from '../../../assets/Delete.svg'
import CurrencyInput from 'react-currency-input-field'
import { replaceNonDigit, sanitizeGlAct } from '../../../util/sanitizeString'
import { updatedToFixed } from '../../../util/setDecimalPrecision'
import { NumericFormat } from 'react-number-format'
import { type IErrorData } from '../../../types/TransactionTypes'

interface ChildProps {
  item: InventoryAccountingDataType
  index: number
  onDelete: () => void
}

const InventoryAcctDataSub: React.FC<ChildProps> = ({ item, index, onDelete }) => {
  const dispatch = useDispatch()
  const { xferDate, dtn, ferc, plantLoc, glAccount, workOrderNumber } = item
  const acres = item.acres
  const amount = updatedToFixed(item.amount, 2)

  const { errorDesc }: IErrorData = useSelector(
    (state: any) => {
      return state.viewPropertyInventory
    }
  )

  const propertyDataError: any[] = useSelector((state: any) => {
    const error = state.viewPropertyInventory.errorData.propertyDataError
    return error
  })

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const inputField = e.target.name
    let { value } = e.target
    const inventoryError = [...propertyDataError]
    const inventoryErrorByIndex = { ...propertyDataError[index] }
    if (inventoryErrorByIndex) {
      inventoryErrorByIndex[inputField] = ''
    }
    inventoryError.splice(index, 1, inventoryErrorByIndex)
    dispatch(updateErrorDescription(inventoryError))

    if (inputField === 'dtn') {
      const val = replaceNonDigit(value)
      value = val?.substring(0, 15)
    }
    if (inputField === 'plantLoc') {
      const val = replaceNonDigit(value)
      value = val?.substring(0, 4)
    }
    if (inputField === 'glAccount' || inputField === 'ferc') {
      const val = sanitizeGlAct(value)
      value = val?.substring(0, 10)
    }
    if (inputField === 'workOrderNumber') {
      value = value?.substring(0, 50)
    }
    // if (inputField === 'acres') {
    //   value = value?.substring(0, 50)
    // }
    dispatch(updateInventoryAccountingData({ data: { [inputField]: value }, index }))
  }

  const handleAmountChange = (value: any, name: any) => {
    dispatch(updateInventoryAccountingData({ data: { [name]: value }, index }))
  }

  const setErrorStyleAndMessage = (fieldName: string) => {
    const StyleAndText = {
      errorStyle: ' ',
      errorText: ''
    }
    const array: any = []
    if (propertyDataError.length > 0) {
      propertyDataError.forEach((item: any, id: number) => {
        if (index === id && item) {
          const text = propertyDataError[id][fieldName] ?? ''
          const style = propertyDataError[id][fieldName] ? 'error-input' : ''
          const _obj = {
            [fieldName]: { errorText: text, errorStyle: style }
          }
          array[id] = { ...array[id], ..._obj }
          StyleAndText.errorText = text
          StyleAndText.errorStyle = style
        }
      })
    }
    return array
  }

  const handleDelete = () => {
    onDelete()
    // handle error array index if any of the line item deleted
    const errorArray = [...propertyDataError]
    if (propertyDataError && propertyDataError.length > 0) {
      errorArray.splice(index, 1)
      dispatch(updateErrorDescription(errorArray))
    }
  }
  return (
    <section className='inventory'>
      <div className='inventory-data-container'>
        <section className='acct-field'>
          <label className="input-label">Xfer Date</label>
          <Input
            name='xferDate'
            value={xferDate ?? ''}
            onChange={handleInputChange}
            className={setErrorStyleAndMessage('xferDate')[index]?.xferDate?.errorStyle}
            errorDesc={setErrorStyleAndMessage('xferDate')[index]?.xferDate?.errorText}
          />
        </section>
        <section className='acct-field'>
          <label className="input-label">DTN</label>
          <Input
            name='dtn'
            className='acct-input'
            value={dtn ?? ''}
            onChange={handleInputChange}
          />
        </section>
        <section className='acct-field'>
          <label className="input-label">FERC</label>
          <Input
            name='ferc'
            value={ferc ?? ''}
            inputType='string'
            onChange={handleInputChange}
            className={setErrorStyleAndMessage('ferc')[index]?.ferc?.errorStyle}
            errorDesc={setErrorStyleAndMessage('ferc')[index]?.ferc?.errorText}
          />
        </section>
        <section className='acct-field'>
          <label className="input-label">Plant Loc</label>
          <Input
            name='plantLoc'
            value={plantLoc ?? ''}
            onChange={handleInputChange}
            className={setErrorStyleAndMessage('plantLoc')[index]?.plantLoc?.errorStyle}
            errorDesc={setErrorStyleAndMessage('plantLoc')[index]?.plantLoc?.errorText}
          />
        </section>
        <section className='acct-field'>
          <label className="input-label">Work Order</label>
          <Input
            name='workOrderNumber'
            value={workOrderNumber ?? ''}
            onChange={handleInputChange}
            className={setErrorStyleAndMessage('workOrderNumber')[index]?.workOrderNumber?.errorStyle}
            errorDesc={setErrorStyleAndMessage('workOrderNumber')[index]?.workOrderNumber?.errorText}
          />
        </section>
        <section className='acct-field'>
          <label className="input-label">GL Acct</label>
          <Input
            name='glAccount'
            value={glAccount ?? ''}
            onChange={handleInputChange}
            className={setErrorStyleAndMessage('glAccount')[index]?.glAccount?.errorStyle}
            errorDesc={setErrorStyleAndMessage('glAccount')[index]?.glAccount?.errorText}
          />
        </section>
        <section className='acct-field'>
          <label className="input-label">Acreage</label>
          {/*
           <CurrencyInput
            id="input-example"
            name="acres"
            placeholder="0.000"
            decimalsLimit={100}
            decimalScale={3}
            onValueChange={(value, name) => { handleAmountChange(value, name) }}
            value={acres}
            allowDecimals={true}
            allowNegativeValue={true}
            className='acct-input-amount'
            autoSave='off'
            autoComplete='off'
            aria-autocomplete="none"
          />
          */}

          <NumericFormat
            name="acres"
            value={acres ?? 0.000}
            thousandSeparator=","
            onChange={handleInputChange}
            placeholder="0.000"
            autoComplete='off'
            aria-autocomplete='none'
            className={`acct-input-amount ${setErrorStyleAndMessage('acres')[index]?.acres?.errorStyle}`}
            id={errorDesc && errorDesc.acres ? 'error-input' : ''}
          />
          {
            setErrorStyleAndMessage('acres')[index]?.acres?.errorText &&
            <p className='error-message'>{setErrorStyleAndMessage('acres')[index]?.acres?.errorText}</p>
          }
        </section>
        <section className='acct-field'>
          <label className="input-label">Amount</label>
          <CurrencyInput
            id="input-example"
            name="amount"
            placeholder="$0.00"
            decimalsLimit={2}
            decimalScale={2}
            onValueChange={(value, name) => { handleAmountChange(value, name) }}
            prefix='$'
            value={amount}
            allowDecimals={true}
            allowNegativeValue={true}
            autoSave='off'
            autoComplete='off'
            aria-autocomplete="none"
            className={`acct-input-amount ${setErrorStyleAndMessage('amount')[index]?.amount?.errorStyle}`}
          />
          {
            setErrorStyleAndMessage('amount')[index]?.amount?.errorText &&
            <p className='error-message'>{setErrorStyleAndMessage('amount')[index]?.amount?.errorText}</p>
          }
        </section>
      </div>
      <div className='delete-icon'>
        <DeleteSVG onClick={handleDelete} />
      </div>
    </section>
  )
}

export default InventoryAcctDataSub

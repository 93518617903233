import React from 'react'
import '../../App.scss'
import { UNAUTHORISED } from '../../Constants'

const UnauthorizedWarningPage: React.FC = () => {
  return (
    <section className="error-container">

      <label className='label-no-data-found-with-y'>{UNAUTHORISED}</label>
    </section>
  )
}

export default UnauthorizedWarningPage

import { createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../config/axios.config'
import { ADD_TRANSACTION_ENDPOINT, UNAUTHORISED_MESSAGE } from '../../Constants'
import { type IAddTransaction } from '../../types/TransactionTypes'

const getTransactionState = (state: any, addTransaction: boolean) => {
  if (addTransaction) return state.addTransaction
  return state.viewTransaction
}

export const updateTransaction = createAsyncThunk('add-transaction/post/id',
  async (arg: { addTransaction: boolean }, thunkAPI) => {
    try {
      const transactionData: IAddTransaction = getTransactionState(thunkAPI.getState(), arg.addTransaction)
      const responseData = await api.put(`/${ADD_TRANSACTION_ENDPOINT}/${transactionData.accountingInformation.transId}`,
        {
          accountingInformation: transactionData.accountingInformation,
          costInformation: transactionData.costInformation,
          crossReference: transactionData.crossReference,
          propertyData: transactionData.propertyData
        }, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
      return responseData.data
    } catch (error: any) {
      if (error.response && error.response.data) {
        const { statusCode } = error.response.data
        if (statusCode === 401) {
          throw new Error(UNAUTHORISED_MESSAGE)
        } else {
          throw error.response.data
        }
      }
    }
  })

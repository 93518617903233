import { GRANTOR_PREPOPULATE_DATA, GTANTEE_PREPOPULATE_DATA, ORGANIZATION_PREPOPULATE_DATA, TRANSACTION_STATUS_PREPOPULATE_DATA } from '../../Constants'

import { type IAccountingInformation, type IAddTransactionType, type ICrossInformation, type IErrorData, type IPropertyDataAccounting, type IPropertyDataAcquisition, type ICostInformation, type IPropertyData, type IPropertyCombinedData } from '../../types/TransactionTypes'
import getCurrentDate from '../../util/getCurrentDate'

export const addTransactionTypeInitialData: IAddTransactionType = {
  type: null,
  section: null
}

export const accountingInformationInitialData: IAccountingInformation = {
  transId: '',
  recordNo: '',
  preparedBy: '',
  preparedDate: getCurrentDate(),
  transactionStatus: TRANSACTION_STATUS_PREPOPULATE_DATA,
  statusDate: getCurrentDate(),
  agent: '',
  propertyNo: '',
  propId: '',
  propertyName: '',
  instrumentTitle: '',
  serialNumber: '',
  executionDate: '',
  documentNo: '',
  documentType: '',
  transactionType: '',
  recordedDate: '',
  grantor: GRANTOR_PREPOPULATE_DATA,
  grantee: GTANTEE_PREPOPULATE_DATA,
  recordingInstrumentNo: '',
  county: '',
  apn: '',
  organization: ORGANIZATION_PREPOPULATE_DATA,
  rpFileNo: '',
  workOrderNumber: '',
  workOrderClosedDate: '',
  legalDescription: '',
  comments: '',
  titleCompany: '',
  escrowCompany: '',
  purchaseNumber: '',
  saleNumber: '',
  thomasPage: '',
  thomasGrid: '',
  structures: false,
  easementReserv: false,
  envImpact: false
}

export const propertyDataAcountingInitialData: IPropertyDataAccounting = {
  parcelNumber: '',
  plantLoc: '',
  fercAcct: '',
  glAcct: '',
  acres: '',
  amount: '',
  mapSize: '',
  acqDocNo: '',
  workOrder: ''
}

export const propertyDataAcquisitionInitialData: IPropertyDataAcquisition = {
  parcelNumber: '',
  plantLoc: '',
  fercAcct: '',
  glAcct: '',
  acres: '',
  amount: '',
  mapSize: '',
  drawing: '',
  ownership: ''
}

export const propertyFieldInitialData: (IPropertyCombinedData) = {
  ...propertyDataAcountingInitialData, ...propertyDataAcquisitionInitialData
}

export const propertyDataInitalData: IPropertyData = {
  fieldData: [propertyFieldInitialData],
  totalAcres: 0,
  totalAmount: 0
}

export const costInformationInitialData: ICostInformation = {
  appraisalValue: '',
  percentageSaleOverAppraisedValue: '',
  totalConsideration: '',
  promissoryNotes: '',
  externalExpense: '',
  adjustment1: '',
  adjustment2: '',
  netProceeds: '',
  structureValue: '',
  book: '',
  grossProfit: '',
  sapTotalInternalExpense: '',
  netGainLoss: '',
  propertyCost: '',
  totalCost: '',
  bookValue: '',
  sapTotalLaborExpenses: '',
  nonLaborExpenses: '',
  overheadExpenses: '',
  total: ''

}

export const crossReferenceInitialData: ICrossInformation = {
  documentType: '',
  documentRef: ''
}

export const errorInitialData: IErrorData = {
  isError: false,
  errorDesc: [],
  propertyDataError: [],
  defaultErr: ''
}

import React from 'react'
import '../people/People.scss'
import { type IInstrumentTitle } from '../../../../types/MaintenanceCategoryTypes'
// import { NumericFormat } from 'react-number-format'
import { ReactComponent as DeleteSVG } from '../../../../assets/Delete.svg'
import { setDeleteItemIndex, updateMaintenanceData } from '../../../../store/slices/maintenanceSlice'
import { useDispatch } from 'react-redux'
import { type AppDispatch } from '../../../../store/store'
import Input from '../../../sharedModules/input/Input'

interface ChildProps {
  item: IInstrumentTitle
  index: number
  onDelete: (event: React.MouseEvent<SVGSVGElement>) => void
}

const InstrumentTitleSub: React.FC<ChildProps> = ({ item, index, onDelete }) => {
  const dispatch = useDispatch<AppDispatch>()
  const { instrumentType, instrumentDesc, instrumentRefNo } = item

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    e.preventDefault()
    let { value } = e.target
    const { name } = e.target
    if (name === 'instrumentDesc') value = value.substring(0, 50)
    if (name === 'instrumentRefNo') value = value.substring(0, 5)
    dispatch(updateMaintenanceData({ data: { [e.target.name]: value }, index }))

    dispatch(updateMaintenanceData({ data: { [e.target.name]: value }, index }))
  }
  const handleDeleteByIndex = (event: React.MouseEvent<SVGSVGElement>) => {
    dispatch(setDeleteItemIndex(index))
    onDelete(event)
  }

  return (
            <div className='people-item-container'>
                <section className='small'>
                    <Input
                        name='instrumentType'
                        className='input-select-box people-input people-id'
                        value={instrumentType ?? ''}
                        onChange={(e) => { handleChange(e, index) }}
                        readOnly={true}
                        // autoComplete='off'
                        // aria-autocomplete='none'
                    />
                </section>
                <section className='large'>
                    <Input
                        name='instrumentDesc'
                        value={instrumentDesc ?? ''}
                        onChange={(e) => { handleChange(e, index) }}
                    />
                </section>
                <section className='small'>
                    <Input
                        name='instrumentRefNo'
                        value={instrumentRefNo ?? ''}
                        onChange={(e) => { handleChange(e, index) }}
                    />
                </section>
                <div className='delete-icon'>
                    <DeleteSVG onClick={(e) => {
                      handleDeleteByIndex(e)
                    }} />
                </div>
            </div>
  )
}
export default InstrumentTitleSub

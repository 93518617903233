/* eslint-disable @typescript-eslint/func-call-spacing */
/* eslint-disable @typescript-eslint/no-confusing-void-expression */
/* eslint-disable @typescript-eslint/brace-style */
/* eslint-disable react/prop-types */
import React, { Suspense, useEffect } from 'react'
import './App.scss'
import { Routes, Route } from 'react-router-dom'
import Welcome from './components/welcome/Welcome'
import Header from './components/sharedModules/header/Header'
import Footer from './components/sharedModules/footer/Footer'
import { isWelcomePopopSkipped } from './util/skipMessageUtil'
import RouteEmail from './components/routeEmail/RouteEmail'
import { navigationPath } from './types/CommonTypes'
import Loader from './components/sharedModules/loader/Loader'
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate, useIsAuthenticated } from '@azure/msal-react'
import { msalInstance } from './config/authConfig'
import Login from './pages/login/Login'
import { useDispatch } from 'react-redux'
import { getDropdownThreshold } from './store/middleWares/getDropdownValues'
import { type AppDispatch } from './store/store'
import loader from './assets/loader.gif'
import Transactions from './pages/transactions/Transactions'
import ViewTransaction from './pages/transactions/viewTransaction/ViewTransaction'
import AddTransaction from './pages/addTransaction/AddTransaction'
import Inventory from './pages/properties/propertyInventory/Inventory'
import ViewProperty from './pages/properties/viewProperty/ViewProperty'
import Properties from './pages/properties/Properties'
import Reports from './pages/reports/Reports'
import Maintenance from './pages/maintenance/Maintenance'
import NotFound404Page from './pages/common/NotFound404'
import AddProperty from './pages/properties/addProperty/AddProperty'
import UnauthorizedWarningPage from './pages/common/UnauthorizedWarningPage'
import { setAuthorized } from './store/slices/authSlice'

function App (): React.ReactElement {
  const dispatch = useDispatch<AppDispatch>()
  const isAuthenticated = useIsAuthenticated()

  useEffect(() => {
    dispatch(getDropdownThreshold())
  }, [])

  useEffect(() => {
    dispatch(setAuthorized())
  }, [isAuthenticated])

  return (
    <div className="app">
      <MsalProvider instance={msalInstance}>
        <AuthenticatedTemplate>
        <RouteEmail />
        <Header />
        {!isWelcomePopopSkipped() && <Welcome />}
        <Loader />
        <Suspense fallback={<div className='loader-container'><img src={loader}></img></div>}>
          <Routes>
          <Route path={navigationPath.ROOT} element={<Login />} />
            <Route path={navigationPath.HOME} element={<Transactions />} />
            <Route path={navigationPath.ADD_TRANSACTION} element={<AddTransaction />} />
            <Route path={navigationPath.VIEW_TRANSACTION_ID_PATTERN} element={<ViewTransaction />} />
            <Route path={navigationPath.ADD_PROPERTY} element={<AddProperty />} />
            <Route path={navigationPath.PROPERTIES} element={<Properties />} />
            <Route path={navigationPath.VIEW_PROPERTY} element={<ViewProperty />} />
            <Route path={navigationPath.VIEW_PROPERTY_INVENTORY} element={<Inventory />} />
            <Route path={navigationPath.REPORTS} element={<Reports />} />
            <Route path={navigationPath.REPORTS_PREFIX} element={<Reports />} />
            <Route path={navigationPath.MAINTENANCE} element={<Maintenance />} />
            <Route path={navigationPath.MAINTENANCE_PREFIX} element={<Maintenance />} />
            <Route path='*' element={<NotFound404Page />} />
            <Route path='/401' element={<UnauthorizedWarningPage />} />
          </Routes>
        </Suspense>
        <Footer />
        </AuthenticatedTemplate>
    <UnauthenticatedTemplate>
      <Login/>
      <Routes>
      <Route path='/' element={<div></div>}/>
      </Routes>
    </UnauthenticatedTemplate>
      </MsalProvider>
    </div>
  )
}
export default App

import React from 'react'
import '../routeOrganizations/RouteOrganizations.scss'
import Button from '../../../sharedModules/button/Button'
import { maintenanceDataMaster, MaintenanceOptionsEnum } from '../../maintenanceOptions'
import MaintenanceCategortHeader from '../maintenanceDataHeader/MaintenanceCategoryHeader'
import { type SORT_DIRECTION } from '../../../../types/CommonTypes'
import { useDispatch, useSelector } from 'react-redux'
import { type IOrderProject } from '../../../../types/MaintenanceCategoryTypes'
import { type AppDispatch } from '../../../../store/store'
import { setDeleteItemIndex, setMaintananceData, updateMaintenanceData } from '../../../../store/slices/maintenanceSlice'
import Input from '../../../sharedModules/input/Input'
import { ReactComponent as DeleteSVG } from '../../../../assets/Delete.svg'

interface ChildProps {
  onChange: () => void
  sort: (sortData: Record<string, SORT_DIRECTION>) => void
  onDelete: (event: React.MouseEvent<SVGSVGElement>) => void
  projectData: IOrderProject[]
}

const OrderProject: React.FC<ChildProps> = ({ onChange, sort, projectData, onDelete }) => {
  const dispatch = useDispatch<AppDispatch>()

  const { sortingOrder } = useSelector((state: any) => {
    return state.maintenance
  })

  const handleInputChange = (e: React.ChangeEvent<HTMLDivElement>) => {
    onChange()
  }
  const onAddButtonClickHandler = (): void => {
    const newData: IOrderProject = {
      orderId: '',
      orderNumber: '',
      projectName: ''
    }
    dispatch(setMaintananceData([...projectData, newData]))
    onChange()
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    e.preventDefault()
    let { value } = e.target
    const { name } = e.target
    if (name === 'orderNumber') value = value.substring(0, 50)
    if (name === 'projectName') value = value.substring(0, 50)

    dispatch(updateMaintenanceData({ data: { [e.target.name]: value }, index }))
  }
  const handleDeleteByIndex = (event: React.MouseEvent<SVGSVGElement>, index: number) => {
    dispatch(setDeleteItemIndex(index))
    onDelete(event)
  }
  const renderComponent = (item: IOrderProject, index: number) => {
    const { orderNumber, projectName } = item

    return (
            <div className='div-container' key={index}>
                <section className='input-section small'>
                    <Input
                        name='orderNumber'
                        value={orderNumber ?? '' }
                        onChange={(e) => { handleChange(e, index) }}
                    />
                </section>
                <section className='input-section large'>
                    <Input
                        name='projectName'
                        value={projectName ?? ''}
                        onChange={(e) => { handleChange(e, index) }}
                    />
                </section>
                <div className='delete-icon'>
                    <DeleteSVG onClick={(e) => {
                      handleDeleteByIndex(e, index)
                    }} />
                </div>
            </div>
    )
  }
  return (
    <div className='container'>
    <div className='Header-container'>
        <label className='heading-label'>Order / Project</label>
    </div>
    <MaintenanceCategortHeader sortFn={sort} sortingOrderObj={sortingOrder}
        headerList={maintenanceDataMaster[MaintenanceOptionsEnum.ORDER_PROJECT].tableHeader}
        smallColumnIndexes={maintenanceDataMaster[MaintenanceOptionsEnum.ORDER_PROJECT].smallColumnIndexes}
        className={'icon'} />
    <div onChange={handleInputChange}>
        {
            projectData?.length > 0 &&
              projectData.map((item: any, index: number) => {
                return renderComponent(item, index)
              })
        }
    </div>
    <div className="add-another-button-container">
        <Button
            buttonType="button"
            className="add-another-button"
            type="confirm"
            onClick={onAddButtonClickHandler}
        >
            +Add Another
        </Button>
    </div>
</div>
  )
}

export default OrderProject

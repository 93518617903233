import React, { type ReactElement, useEffect, useState } from 'react'
import './Welcome.scss'
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader
} from '../sharedModules/modal/Modal'
import Button from '../sharedModules/button/Button'
// import { useDispatch } from 'react-redux'
import { ReactComponent as WarningSvg } from '../../assets/WarningIcon.svg'
import { DELETE_MAINTENANCE_WARNING, DELETE_MODAL_TEXT, DELETE_PROPERTY_MODAL_TEXT, MAINTENANCE_UPDATE_DELETE_ENUM, PROPERTY_UPDATE_DELETE_ENUM, UPDATE_BOOKED_CONFIRM_MESSAGE, UPDATE_DELETE_ENUM, UPDATE_PROPERTY_MODAL_TEXT } from '../../Constants'
// import { type AppDispatch } from '../../store/store'

interface IProps {
  view: boolean
  txnOpertionEnum: UPDATE_DELETE_ENUM | PROPERTY_UPDATE_DELETE_ENUM | MAINTENANCE_UPDATE_DELETE_ENUM
  warningLabel: string
  modalAction: string
  // message: string
  confirmFn: () => void
  cancelFn: () => void
}

const WarningModal: React.FC<IProps> = ({ view, confirmFn, cancelFn, txnOpertionEnum, warningLabel, modalAction }): ReactElement | null => {
  const [viewModal, setViewModal] = useState<boolean>(view)
  // const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    setViewModal(view)
  }, [view])

  const onConfirmandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    confirmFn()
  }

  const onCancelHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    cancelFn()
  }

  const getModalText = (operation: string) => {
    let modalTxt = ''
    switch (operation) {
      case UPDATE_DELETE_ENUM.DELETE:
        modalTxt = DELETE_MODAL_TEXT
        break
      case UPDATE_DELETE_ENUM.UPDATE:
        modalTxt = UPDATE_BOOKED_CONFIRM_MESSAGE
        break
      case PROPERTY_UPDATE_DELETE_ENUM.DELETE:
        modalTxt = DELETE_PROPERTY_MODAL_TEXT
        break
      case PROPERTY_UPDATE_DELETE_ENUM.UPDATE:
        modalTxt = UPDATE_PROPERTY_MODAL_TEXT
        break
      case MAINTENANCE_UPDATE_DELETE_ENUM.DELETE:
        modalTxt = DELETE_MAINTENANCE_WARNING
        break
    }
    return modalTxt
  }

  if (!viewModal) { return null }

  return (
    <Modal className="modal">
      <ModalHeader>
        <section className="welcome-header">
          <h4>{warningLabel}</h4>
        </section>
      </ModalHeader>
      <ModalBody>
        <div className="div-modal-body">
          <div className="modal-icon">
            <WarningSvg />
          </div>
          <div>
            <p>{getModalText(txnOpertionEnum)}</p>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="button-container">
        <Button type="cancel" onClick={onCancelHandler}>
            Cancel
          </Button>
          <Button type="confirm" onClick={onConfirmandler}>
            {modalAction}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default WarningModal

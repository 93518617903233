import React, { type ReactElement } from 'react'
import SubHeader from '../subHeader/SubHeader'
import './Header.scss'
import logo from '../../../assets/reis_logo.svg'

const Header = (): ReactElement => {
  return (
    <>
      <nav className="main-header">
        <div className="reis-logo-container">
          <img src={logo} className='reis-logo' alt="reis-logo" />
        </div>
      </nav>
      <SubHeader/>
    </>
  )
}

export default Header

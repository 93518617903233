import React from 'react'
import { styles } from '../propertyAcquisition/propertyAcquisitionStyles'
import { Text, View } from '@react-pdf/renderer'
import { setDecimalPrecision } from '../../../../util/setDecimalPrecision'
import { type ICostInformation } from '../../../../types/TransactionTypes'
import { calculateNetGainLoss } from '../../../../util/costCalculations'
import { MONEY } from '../../../../Constants'

const PropertyDisposition = (props: { costInformation: ICostInformation }): React.ReactElement => {
  const { costInformation } = props
  return (
    <View style={styles.acqContainer}>
      <View style={styles.acqTitle}>
        <Text>Disposition Costs</Text>
      </View>
      <View style={styles.container}>
        <View style={styles.row}>
          <Text style={styles.subHeading}>Total Consideration:</Text>
          <Text style={styles.val}>{setDecimalPrecision(costInformation?.totalConsideration, 2, MONEY)}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.subHeading}>External Expense:</Text>
          <Text style={styles.val}>{setDecimalPrecision(costInformation?.externalExpense, 2, MONEY)}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.subHeading}>Structure Value:</Text>
          <Text style={styles.val}>{setDecimalPrecision(costInformation?.structureValue, 2, MONEY)}</Text>
        </View>
      </View>
      <View style={styles.container}>
        <View style={styles.row}>
          <Text style={styles.subHeading}>Appraised value:</Text>
          <Text style={styles.val}>{setDecimalPrecision(costInformation?.appraisalValue, 2, MONEY)}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.subHeading}>SAP Actual Total/Labour Exp:</Text>
          <Text style={styles.val}>{setDecimalPrecision(costInformation?.sapTotalLaborExpenses, 2, MONEY)}</Text>
        </View>
        <View style={styles.row}></View>
      </View>
      <View style={styles.container}>
        <View style={styles.row}>
          <Text style={styles.subHeading}>Net Proceeds:</Text>
          <Text style={styles.val}>{setDecimalPrecision(costInformation?.netProceeds, 2, MONEY)}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.subHeading}>Net Gain/Loss:</Text>
          <Text style={styles.val}>{setDecimalPrecision(calculateNetGainLoss(costInformation), 2, MONEY)}</Text>
        </View>
        <View style={styles.row}></View>
      </View>
    </View>
  )
}

export default PropertyDisposition

import { combineReducers } from '@reduxjs/toolkit'
import addTransactionReducer from './slices/addTransactionSlice'
import routeEmailReducer from './slices/routeEmailSlice'
import reportReducer from './slices/reportsSlice'
import transactionReducer from './slices/transactionSlice'
import dropDownReducer from './slices/dropDownSlice'
import propertyReducer from './slices/propertySlice'
import viewPropertyReducer from './slices/viewPropertySlice'
import viewTransactionReducer from './slices/viewTransactionSlice'
import viewPropertyInventoryReducer from './slices/viewPropertyInventorySlice'
import searchReducer from './slices/searchSlice'
import MaintenanceReducer from './slices/maintenanceSlice'
import addPropertyReducer from './slices/addPropertySlice'
import authReducer from './slices/authSlice'

const rootReducer = combineReducers({
  addTransaction: addTransactionReducer,
  routeEmail: routeEmailReducer,
  report: reportReducer,
  transaction: transactionReducer,
  dropDown: dropDownReducer,
  property: propertyReducer,
  viewProperty: viewPropertyReducer,
  viewTransaction: viewTransactionReducer,
  viewPropertyInventory: viewPropertyInventoryReducer,
  search: searchReducer,
  maintenance: MaintenanceReducer,
  addProperty: addPropertyReducer,
  auth: authReducer
})

export default rootReducer

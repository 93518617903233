export enum navigationPath {
  ROOT = '/',
  HOME = '/transactions',
  ADD_TRANSACTION = '/add-transaction',
  VIEW_TRANSACTION = '/transactions',
  VIEW_TRANSACTION_ID_PATTERN = '/transactions/:recordNo',
  REPORTS = '/reports',
  REPORTS_PREFIX = '/reports/:reportName',
  REPORTS_PROPERTIES = '/reports/property-list',
  REPORTS_BY_GL_ACC = '/reports/entries-by-gl-account',
  PROPERTIES = '/properties',
  ADD_PROPERTY = '/add-property',
  VIEW_PROPERTY = '/properties/:id',
  VIEW_PROPERTY_INVENTORY = '/properties/inventory/:id',
  VIEW_PROPERTY_INVENTORY_DETAIL = '/properties/inventory',
  MAINTENANCE = '/maintenance',
  MAINTENANCE_PREFIX = '/maintenance/:category'
}

export enum TRANSACTION_TYPE_SECTION {
  DISPOSITION = 'DISPOSITION',
  ACQUISITION = 'ACQUISITION',
}

export enum ADD_TRANSACTION_TYPE {
  QUITCLAIM = 'QUITCLAIM',
  GRANTOUT = 'GRANTOUT',
  SALE = 'SALE',
  LANDPURCHASE = 'LANDPURCHASE',
  EASEMENT = 'EASEMENT',
}

export enum VIEW_TRANSACTION_TYPE {
  RELINQUISH = 'Relinquish',
  ACQUISITION = 'Acquisition',
  AMENDMENT = 'Amendment'
}

export enum duplicateComponentActionTypes {
  ADD = 'add',
  RENDER_VIEW = 'render-view',
  REFRESH_VIEW = 'refresh-view'
}

export enum DropDownFiledsEnum {
  AGENT = 'agent',
  TRANSACTION_STATUS = 'trStatus',
  PROPERTY = 'property',
  INSTRUMENT_TITLE = 'instTitle',
  DOCUMENT_TYPE = 'docType',
  TRANSACTION_DOC_TYPE = 'addTransDocType',
  TRANSACTION_TYPE = 'trType',
  COUNTY = 'county',
  ORGANIZATION = 'org',
  TITLE_COMPANY = 'titleComp',
  ESCROW_COMPANY = 'escrowComp',
  PLANT_LOCATION = 'plantLoc',
  FERC_ACCT = 'fercAcct',
  GL_ACCT = 'glAcct',
  OWNERSHIP = 'ownership',
  ACQ_DOC_NO = 'acqDocNo',
  PARCEL_NUMBER = 'parcelNo',
  CITY = 'city',
  STATE = 'state',
  PROPERTY_TYPE = 'propertyType'
}

export enum VIEW_TRANSACTION_STATUS {
  // AVAILABLE = 'available',
  BOOKED = 'Booked',
  // CLOSED = 'Closed',
  // OPEN = 'Open',
  POST = 'Post',
  NEW = 'New'

}

export enum COUNTY_MAPPING {
  RIVERSIDE = 'Riverside',
  LOS_ANGELES = 'Los Angeles',
  VENTURA = 'Ventura',
  ORANGE = 'Orange',
  SANTA_BARBARA = 'Santa Barbara',
  SAN_BERNARDINO = 'San Bernardino',
  SAN_DIEGO = 'San Diego',
  FRESNO = 'Fresno',
  IMPERIAL = 'Imperial',
  INYO = 'Inyo',
  KERN = 'Kern',
  KINGS = 'Kings',
  MADERA = 'Madera',
  MONO = 'Mono',
  CLARK = 'Clark',
  LA_PAZ = 'La Paz',
  MARICOPA = 'Maricopa',
  TULARE = 'Tulare',
  UNKNOWN = 'Unknown 1',
  YUMA = 'Yuma'
}

export enum StatusTypesEnum {
  LOADING = 'loading',
  ERROR = 'error',
  SUCCESS = 'success'
}

export enum PDF_TYPES {
  VIEW_TRANSACTION_PDF = 'view-transaction-pdf',
  ADD_TRANSACTION_PDF = 'add-transaction-pdf',
  PROPERTY_INVENTORY_PDF = 'property-inventory-pdf',
  PROPERTY_INVENTORY_PDF_MAIN = 'property-inventory-pdf-main'
}

export enum SORT_DIRECTION {
  ASC = 'ASC',
  DSC = 'DESC'
}

export enum MAINTENANCE_PLACEHOLDER {
  PEOPLE = 'Enter Last name',
  ROUTING_ORGANIZATIONS = 'Enter Organization name',
  COMPANIES = 'Enter Company name',
  INSTRUMENT_TYPE = 'Enter Description',
  DOCUMENT_TYPE = 'Enter Doc Type',
  GL_ACCOUNT = 'Enter GL Account',
  PLANT_LOCATION = 'Enter Plant Location',
  FERC_ACCOUNTS = 'Enter FERC No',
  ORDER_PROJECT = 'Enter Order No',
  DEFAULT = 'Enter search item'
}

export interface ErrorPayload {
  status: number
  message: string
}

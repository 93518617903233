import React, { type ReactElement } from 'react'
import './RouteOrganizations.scss'
import { useDispatch, useSelector } from 'react-redux'
import { MaintenanceOptionsEnum, maintenanceDataMaster } from '../../maintenanceOptions'
import MaintenanceCategortHeader from '../maintenanceDataHeader/MaintenanceCategoryHeader'
import { type IRoutingOrg } from '../../../../types/MaintenanceCategoryTypes'
import Input from '../../../sharedModules/input/Input'
import { type AppDispatch } from '../../../../store/store'
import { setDeleteItemIndex, setMaintananceData, updateMaintenanceData } from '../../../../store/slices/maintenanceSlice'
import Button from '../../../sharedModules/button/Button'
import { ReactComponent as DeleteSVG } from '../../../../assets/Delete.svg'
import { type SORT_DIRECTION } from '../../../../types/CommonTypes'

interface ChildProps {
  onChange: () => void
  sort: (sortData: Record<string, SORT_DIRECTION>) => void
  onDelete: (event: React.MouseEvent<SVGSVGElement>) => void
  routingOrgs: IRoutingOrg[]
}

const RouteOrganizations: React.FC<ChildProps> = ({ sort, onChange, onDelete, routingOrgs }): ReactElement => {
  const dispatch = useDispatch<AppDispatch>()
  const { sortingOrder } = useSelector((state: any) => {
    return state.maintenance
  })

  const handleDeleteByIndex = (event: React.MouseEvent<SVGSVGElement>, index: number) => {
    dispatch(setDeleteItemIndex(index))
    onDelete(event)
  }
  const handleInputChange = (e: React.ChangeEvent<HTMLDivElement>) => {
    onChange()
  }
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    e.preventDefault()
    let { value } = e.target
    const { name } = e.target
    if (name === 'organizationName') value = value.substring(0, 75)
    dispatch(updateMaintenanceData({ data: { [e.target.name]: value }, index }))
  }

  const onAddButtonClickHandler = (): void => {
    const newData: IRoutingOrg = {
      organizationId: '',
      organizationName: ''
    }
    dispatch(setMaintananceData([...routingOrgs, newData]))
    onChange()
  }

  return (
    <div>
      <div className='container'>
        <div className='header-container'>
          <label className='heading-label'>Routing Organizations</label>
        </div>
        <div>
          <MaintenanceCategortHeader sortFn={sort} sortingOrderObj={sortingOrder}
            headerList={maintenanceDataMaster[MaintenanceOptionsEnum.ROUTING_ORGANIZATION].tableHeader}
            smallColumnIndexes={maintenanceDataMaster[MaintenanceOptionsEnum.ROUTING_ORGANIZATION].smallColumnIndexes}
            className={'icon'} />
        </div>
        <div onChange={handleInputChange}>
          {
            routingOrgs?.length > 0 &&
              routingOrgs.map((item, index) => {
                return (
                <div className='div-container' key={index}>
                  <section key={index} className='input-section'>
                    <Input
                      name='organizationName'
                      value={item.organizationName ?? ''}
                      onChange={(e) => { handleChange(e, index) }} />
                  </section>
                  <div className='delete-icon'>
                    <DeleteSVG onClick={(e) => {
                      handleDeleteByIndex(e, index)
                    }} />
                  </div>
                </div>
                )
              })
            }
        </div>
        <div className="add-another-button-container">
          <Button
            buttonType="button"
            className="add-another-button"
            type="confirm"
            onClick={onAddButtonClickHandler}
          >
            +Add Another
          </Button>
        </div>
      </div>
    </div>
  )
}

export default RouteOrganizations

import { StyleSheet } from '@react-pdf/renderer'

export const styles = StyleSheet.create({
  container: {
    paddingTop: 8,
    display: 'flex',
    flexDirection: 'column'
  },
  docInfo: {
    textAlign: 'left',
    fontSize: 12,
    borderBottom: 0.7,
    fontFamily: 'Helvetica-Bold',
    borderColor: '#707372',
    width: '100%',
    paddingBottom: 3
  },
  propActivity: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 10,
    fontFamily: 'Helvetica'
  },
  activity: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    fontFamily: 'Helvetica',
    marginTop: 2
  },
  crossRefernce: {
    flex: 25,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start'
  },
  docRef: {
    fontFamily: 'Helvetica',
    marginTop: 5
  },
  docRefLabel: {
    fontFamily: 'Helvetica'
  },
  propActivityData: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flex: 75
  },
  activityData: {
    display: 'flex',
    flex: 15,
    marginRight: 25
  },
  propertyActivityLabel: {
    marginBottom: 3,
    fontFamily: 'Helvetica'
  },
  propertyActivityLabel1: {
    marginBottom: 3,
    fontFamily: 'Helvetica',
    textAlign: 'right'
  },
  total: {
    marginBottom: 3,
    fontFamily: 'Helvetica-Bold'
  },
  total1: {
    marginBottom: 3,
    fontFamily: 'Helvetica-Bold',
    textAlign: 'right'
  },
  propertyActivityVal: {
    marginBottom: 3,
    color: '#757575'
  },
  propertyActivityVal1: {
    marginBottom: 3,
    color: '#757575',
    textAlign: 'right'
  },
  val: {
    color: '#757575'
  },
  activityData1: {
    display: 'flex',
    flex: 30,
    marginRight: 15,
    alignItems: 'flex-end'
  }
})

import { createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../config/axios.config'
import { DOWNLOAD_REPORT } from '../../Constants'
import { REPORT_EXPORT_TYPE } from '../../components/reports/predefinedReportMapping'
import { getReportUrl } from './getReport'
import { type IReports } from '../slices/reportsSlice'

const getSelectedReportAndParams = (state: any) => {
  const reportData: IReports = state.report
  return {
    selectedReport: reportData.selectedReport,
    params: {
      glAccount: reportData.glAccount,
      workAllocationReport1: reportData.workAllocationReport1,
      varianceByPlantLocation: reportData.varianceByPlantLocation,
      workAllocationReport2: reportData.workAllocationReport2,
      rangeOfPlantLocation: reportData.rangeOfPlantLocation,
      fullPartialPL: reportData.fullPartialPL,
      propertyInventoryDetails: reportData.propertyInventoryDetails,
      resReport: reportData.resReport,
      reoReport: reportData.reoReport
    }
  }
}

export const downloadReport = createAsyncThunk('reports/download/:reportName?exportType',
  async (arg: { exportType: REPORT_EXPORT_TYPE }, thunkAPI): Promise<any> => {
    try {
      const { selectedReport, params } = getSelectedReportAndParams(thunkAPI.getState())
      const reportUrl = getReportUrl(selectedReport, params)
      const reportTypeUrl = reportUrl.includes('?') ? `&reportType=${arg.exportType}` : `?reportType=${arg.exportType}`
      const response = await api.get(`/${DOWNLOAD_REPORT}/${reportUrl}${reportTypeUrl}`, {
        responseType: 'blob'
      })
      const headerMetaData = JSON.parse(response.headers['x-metadata-report'] ?? '')
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const a = document.createElement('a')
      a.href = url
      a.download = headerMetaData.fileName ?? `Report${arg.exportType === REPORT_EXPORT_TYPE.excel
        ? '.xlsx'
     : '.pdf'}`
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)
      return headerMetaData.message
    } catch (error: any) {
      throw error?.response?.data
    }
  })

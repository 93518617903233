import React from 'react'
import './Header.scss'
import { ReactComponent as TriangleSVG } from '../../../../assets/Triangle.svg'
import { SORT_DIRECTION } from '../../../../types/CommonTypes'

interface ChildProps {
  sortFn: (sortData: Record<string, SORT_DIRECTION>) => void
  sortingOrderObj: Record<any, SORT_DIRECTION>
  className?: any
  headerList: any
  smallColumnIndexes?: number[]
}

const MaintenanceCategortHeader: React.FC<ChildProps> = ({ sortFn, sortingOrderObj, headerList, className, smallColumnIndexes }) => {
  const renderSortButton = (dataKey: string, index: number) => {
    const sortOrder = sortingOrderObj[dataKey]
    return (
      <div
      onClick={(e) => {
        e.preventDefault()
        sortFn({ [dataKey]: sortOrder })
      }}>
        <div className={sortingOrderObj[dataKey] === SORT_DIRECTION.DSC ? '' : 'reverse'}>
        <TriangleSVG />
        </div>
      </div>
    )
  }

  return (
    <div className='header-container'>
    <div className='category-header-container'>
      {
        headerList.map(({ column, dataKey }: any, index: any) => (
          smallColumnIndexes?.includes(index)
            ? <section key={index} className={`header-with-icon small ${className ?? ''}`}>
              <label className='input-label'>{column}</label>
              { renderSortButton(dataKey, index)}
            </section>
            : <section key={index} className={`header-with-icon large ${className ?? ''}`}>
              <label className='input-label'>{column}</label>
              {renderSortButton(dataKey, index)}
            </section>
        ))
      }
    </div>
    </div>
  )
}

export default MaintenanceCategortHeader

import React from 'react'
import loader from '../../../assets/loader.gif'
import './Loader.scss'
import { useSelector } from 'react-redux'
import { StatusTypesEnum } from '../../../types/CommonTypes'

const Loader = () => {
  const statuses: string[] =
  [useSelector((state: any) => state.addTransaction.status.type),
    useSelector((state: any) => state.report.status.type),
    useSelector((state: any) => state.transaction.status.type),
    useSelector((state: any) => state.property.status.type),
    useSelector((state: any) => state.viewProperty.status.type),
    useSelector((state: any) => state.viewTransaction.status.type),
    useSelector((state: any) => state.viewPropertyInventory.status.type),
    useSelector((state: any) => state.report.exportStatus.type),
    useSelector((state: any) => state.viewPropertyInventory.status.type),
    useSelector((state: any) => state.routeEmail.status.type),
    useSelector((state: any) => state.maintenance.status.type),
    useSelector((state: any) => state.addProperty.status.type)
  ]

  if (!statuses.some((status: string) => status === StatusTypesEnum.LOADING)) {
    return
  }
  return (
    <div className='loader-container'>
      <img src={loader}></img>
    </div>
  )
}

export default Loader

import React from 'react'
import { Text, View } from '@react-pdf/renderer'
import { styles } from './propertyActivityStyles'
import { setDecimalPrecision } from '../../../../util/setDecimalPrecision'
import { MONEY } from '../../../../Constants'
import { VIEW_TRANSACTION_TYPE } from '../../../../types/CommonTypes'

const PropertyActivity = ({ crossReference, propertyData, accountingInformation, costInformation }: any): React.ReactElement => {
  const propertyDataMutable: any[] = []
  propertyData?.fieldData?.forEach((value: any) => propertyDataMutable.push(Object.assign({}, value)))
  const propertyDataSorted: any[] = propertyDataMutable.sort((a: any, b: any) => {
    return a.parcelNumber?.localeCompare(b.parcelNumber)
  })

  return (
    <View style={styles.container}>
      <View style={styles.docInfo}>
        <Text>Document Number(s) Information</Text>
      </View>
      <View style={styles.propActivity}>
        <View style={styles.propActivityData}>
          <View style={styles.activity}>
            <View style={styles.activityData}>
              <Text style={styles.propertyActivityLabel}>Acq Doc #s:</Text>
            </View>
            <View style={styles.activityData}>
              <Text style={styles.propertyActivityLabel}>Parcel(s):</Text>
            </View>
            <View style={styles.activityData1}>
              <Text style={styles.propertyActivityLabel1}>Acq. Acreage:</Text>
            </View>
            <View style={styles.activityData1}>
              <Text style={styles.propertyActivityLabel1}>Book Value:</Text>
            </View>
          </View>
          {
            propertyDataSorted.map((item: any, index: number) => {
              const amount = String(item.amount).replace(/\$/g, '')
              return (
                <View style={styles.activity} key={index}>
                  <View style={styles.activityData}>
                    <Text style={styles.propertyActivityVal}>{item.acqDocNo ? item.acqDocNo : accountingInformation.documentNo}</Text>
                  </View>
                  <View style={styles.activityData}>
                    <Text style={styles.propertyActivityVal}>{item.parcelNumber}</Text>
                  </View>
                  <View style={styles.activityData1}>
                    <Text style={styles.propertyActivityVal1}>{setDecimalPrecision(item.acres, 3)}</Text>
                  </View>
                  <View style={styles.activityData1}>
                    <Text style={styles.propertyActivityVal1}>{setDecimalPrecision(amount, 2, MONEY)}</Text>
                  </View>
                </View>
              )
            })
          }
          <View style={styles.activity}>
            <View style={styles.activityData}></View>
            <View style={styles.activityData}></View>
            <View style={styles.activityData1}>
              <Text style={styles.total1}>{setDecimalPrecision(propertyData.totalAcres, 3)}</Text>
            </View>
            <View style={styles.activityData1}>
              {/* <Text style={styles.total}>{setDecimalPrecision(propertyData.totalAmount, 2, MONEY)}</Text> */}
              <Text style={styles.total1}>{(accountingInformation.transactionType === VIEW_TRANSACTION_TYPE.ACQUISITION ||
                    accountingInformation.transactionType === VIEW_TRANSACTION_TYPE.AMENDMENT)
                ? setDecimalPrecision(costInformation.totalCost, 2, MONEY)
                : setDecimalPrecision(propertyData.totalAmount, 2, MONEY)}</Text>
            </View>
          </View>
        </View>
        <View style={styles.crossRefernce}>
          <Text style={styles.docRefLabel}>Cross Ref. Doc #s:</Text>
          {
            crossReference.map((item: any, index: number) => {
              return (
                <View style={styles.docRef} key={index}>
                  <Text style={styles.val}>{item?.documentRef}</Text>
                </View>
              )
            })
          }
        </View>
      </View>
    </View>
  )
}

export default PropertyActivity

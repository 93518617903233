import React from 'react'
import '../people/People.scss'
import { useDispatch, useSelector } from 'react-redux'
import { type AppDispatch } from '../../../../store/store'
import { type IInstrumentTitle } from '../../../../types/MaintenanceCategoryTypes'
import { setMaintananceData } from '../../../../store/slices/maintenanceSlice'
import { type SORT_DIRECTION } from '../../../../types/CommonTypes'
import { MaintenanceOptionsEnum, maintenanceDataMaster } from '../../maintenanceOptions'
import MaintenanceCategortHeader from '../maintenanceDataHeader/MaintenanceCategoryHeader'
import Button from '../../../sharedModules/button/Button'
import InstrumentTitleSub from './InstrumentTypeSub'

interface ChildProps {
  onChange: () => void
  sort: (sortData: Record<string, SORT_DIRECTION>) => void
  onDelete: (event: React.MouseEvent<SVGSVGElement>) => void
  instrumentTitleData: IInstrumentTitle[]

}

export const InstrumentTitle: React.FC<ChildProps> = ({ sort, onChange, onDelete, instrumentTitleData }) => {
  const dispatch = useDispatch<AppDispatch>()

  const { sortingOrder } = useSelector((state: any) => {
    return state.maintenance
  })

  const onAddButtonClickHandler = (): void => {
    const newData: IInstrumentTitle = {
      instrumentType: '',
      instrumentDesc: '',
      instrumentRefNo: ''
    }
    dispatch(setMaintananceData([...instrumentTitleData, newData]))
    onChange()
  }
  const handleInputChange = (e: React.ChangeEvent<HTMLDivElement>) => {
    onChange()
  }

  return (
    <div className='container'>
      <div className='header-container'>
        <label className='heading-label'>Instrument Type</label>
      </div>
      <MaintenanceCategortHeader sortFn={sort} sortingOrderObj={sortingOrder}
        headerList={maintenanceDataMaster[MaintenanceOptionsEnum.INSTRUMENT_TYPE].tableHeader}
        smallColumnIndexes={maintenanceDataMaster[MaintenanceOptionsEnum.INSTRUMENT_TYPE].smallColumnIndexes}
        />
      <div onChange={handleInputChange}>
        {
          instrumentTitleData?.length > 0 &&
            instrumentTitleData.map((item, index) => {
              return (
              <div className='people-data-container' key={index}>
                <InstrumentTitleSub item={item} index={index} key={index} onDelete={onDelete} />
              </div>
              )
            })
        }
      </div>
      <div className="add-another-button-container">
        <Button
          buttonType="button"
          className="add-another-button"
          type="confirm"
          onClick={onAddButtonClickHandler}
        >
          +Add Another
        </Button>
      </div>
    </div>

  )
}

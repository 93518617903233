import React from 'react'
import { Page, Text, Document } from '@react-pdf/renderer'
import { styles } from './propertyAccounting/propertyAccountingStyles'
import { type IAddTransaction } from '../../../types/TransactionTypes'
import PropertyAccouting from './propertyAccounting/PropertyAccouting'
import PropertyAquisition from './propertyAcquisition/PropertyAquisition'
import PropertyActivity from './propertyActivity/PropertyActivity'
import PropertyDisposition from './propertyDisposition/PropertyDisposition'
import { VIEW_TRANSACTION_TYPE } from '../../../types/CommonTypes'
import { type ITransactionView } from '../../../store/slices/viewTransactionSlice'

const TransactionPDFmain = (props: {
  transactionData: IAddTransaction | ITransactionView
}): React.ReactElement => {
  const {
    accountingInformation,
    costInformation,
    crossReference,
    propertyData
  } = props.transactionData
  return (
    <Document pageMode={'fullScreen'}>
      <Page size="A4" style={styles.page}>
        <Text style={styles.title}>Property Inventory Notice</Text>
        <PropertyAccouting accountingInformation={accountingInformation} propertyData={propertyData} costInformation={costInformation}/>
        {(accountingInformation.transactionType === VIEW_TRANSACTION_TYPE.ACQUISITION || accountingInformation.transactionType === VIEW_TRANSACTION_TYPE.AMENDMENT) &&
          <PropertyAquisition costInformation={costInformation} />}
        {(accountingInformation.transactionType === VIEW_TRANSACTION_TYPE.RELINQUISH) &&
          <PropertyDisposition costInformation={costInformation} />
        }
        <PropertyActivity costInformation={costInformation} crossReference={crossReference} propertyData={propertyData} accountingInformation={accountingInformation} />
      </Page>
    </Document>
  )
}

export default TransactionPDFmain

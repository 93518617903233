import {
  sortingOrderInitialData
  , type IPeople, type IRoutingOrg, routingOrgSortingOrderInitialData
  , type ICompany, companySortingOrderInitialData, type IInstrumentTitle
  , InstrumentTitleSortingOrderInitialData, type IDocumentType, documentTypeSortingOrderInitialData
  , type IGLAccount, GLAccountSortingInitialData, type IFERCAccount, FERCAccountSortingInitialData
  , type IPlanLoc, plSortingInitialData, type IOrderProject, orderSortingInitialData
} from '../../types/MaintenanceCategoryTypes'
import { formatString } from '../../util/formatStrings'
import { companyDataHeadings, documentTypeDataHeadings, fercAccountDataHeadings, glAccountDataHeadings, instrumentTitleDataHeadings, orderProjectDataHeading, orgDataHeadings, peopleDataHeadings, plDataHeadings } from './maintenanceHeaderMapping'

export enum MaintenanceOptionsEnum {
  PEOPLE = 'people',
  ROUTING_ORGANIZATION = 'routing_organization',
  COMPANIES = 'companies',
  INSTRUMENT_TYPE = 'instrument_type',
  DOCUMENT_TYPE = 'document_type',
  GL_ACCOUNT = 'gl_account',
  PLANT_LOCATION = 'plant_location',
  FERC_ACCT = 'ferc_acct',
  ORDER_PROJECT = 'order_project'
}

export const maintanenceCategoryObject: Array<{ id: MaintenanceOptionsEnum, label: string }> = [
  { id: MaintenanceOptionsEnum.PEOPLE, label: 'People' },
  { id: MaintenanceOptionsEnum.ROUTING_ORGANIZATION, label: 'Routing Organizations' },
  { id: MaintenanceOptionsEnum.GL_ACCOUNT, label: 'GL Accounts' },
  { id: MaintenanceOptionsEnum.FERC_ACCT, label: 'FERC Accounts' },
  { id: MaintenanceOptionsEnum.PLANT_LOCATION, label: 'Plant Location' },
  { id: MaintenanceOptionsEnum.COMPANIES, label: 'Companies' },
  { id: MaintenanceOptionsEnum.ORDER_PROJECT, label: 'Order/Project' },
  { id: MaintenanceOptionsEnum.DOCUMENT_TYPE, label: 'Document Types' },
  { id: MaintenanceOptionsEnum.INSTRUMENT_TYPE, label: 'Instrument Type' }
]
export const maintenanceOptionsData = (): { id: string[], options: any[] } => {
  return {
    id: [
      MaintenanceOptionsEnum.PEOPLE,
      MaintenanceOptionsEnum.ROUTING_ORGANIZATION,
      MaintenanceOptionsEnum.COMPANIES,
      MaintenanceOptionsEnum.INSTRUMENT_TYPE,
      MaintenanceOptionsEnum.DOCUMENT_TYPE,
      MaintenanceOptionsEnum.GL_ACCOUNT,
      MaintenanceOptionsEnum.PLANT_LOCATION,
      MaintenanceOptionsEnum.FERC_ACCT,
      MaintenanceOptionsEnum.ORDER_PROJECT
    ],
    options: [
      'People',
      'Routing Organizations',
      'Companies',
      'Instrument Type',
      'Document Types',
      'GL Accounts',
      'Plant Location',
      'FERC Accounts',
      'Order/Project'
    ]
  }
}

type maintenanceDataMasterType<T extends string> = { [key in T]: {
  updateData: (data: any) => any
  tableHeader: any
  smallColumnIndexes: number[]
  sortingOrder: any
  initialSortColumn: string
  searchKeyColumn: string
} }

export const maintenanceDataMaster: maintenanceDataMasterType<MaintenanceOptionsEnum> = {
  [MaintenanceOptionsEnum.PEOPLE]: {
    updateData: (data: IPeople[]): any => {
      return data.map((_item: IPeople) => {
        return {
          ..._item,
          firstName: formatString(_item.firstName),
          lastName: formatString(_item.lastName),
          initial: formatString(_item.initial),
          title: formatString(_item.title)
        }
      })
    },
    tableHeader: peopleDataHeadings,
    smallColumnIndexes: [0, 3],
    sortingOrder: sortingOrderInitialData,
    initialSortColumn: 'employeeId',
    searchKeyColumn: 'lastName'
  },
  [MaintenanceOptionsEnum.ROUTING_ORGANIZATION]: {
    updateData: (data: IRoutingOrg[]): any => {
      return data
    },
    smallColumnIndexes: [0],
    tableHeader: orgDataHeadings,
    sortingOrder: routingOrgSortingOrderInitialData,
    initialSortColumn: 'organizationId',
    searchKeyColumn: 'organizationName'
  },
  [MaintenanceOptionsEnum.COMPANIES]: {
    updateData: (data: ICompany[]): any => {
      return data
    },
    tableHeader: companyDataHeadings,
    sortingOrder: companySortingOrderInitialData,
    initialSortColumn: 'companyId',
    smallColumnIndexes: [0],
    searchKeyColumn: 'companyName'
  },
  [MaintenanceOptionsEnum.DOCUMENT_TYPE]: {
    updateData: (data: IDocumentType[]): any => {
      return data
    },
    tableHeader: documentTypeDataHeadings,
    initialSortColumn: 'docType',
    sortingOrder: documentTypeSortingOrderInitialData,
    smallColumnIndexes: [0],
    searchKeyColumn: 'docType'
  },
  [MaintenanceOptionsEnum.FERC_ACCT]: {
    updateData: (data: IFERCAccount[]): any => {
      return data
    },
    tableHeader: fercAccountDataHeadings,
    initialSortColumn: 'fercNo',
    smallColumnIndexes: [0],
    sortingOrder: FERCAccountSortingInitialData,
    searchKeyColumn: 'fercNo'
  },
  [MaintenanceOptionsEnum.GL_ACCOUNT]: {
    updateData: (data: IGLAccount[]): any => {
      return data
    },
    tableHeader: glAccountDataHeadings,
    initialSortColumn: 'glAccount',
    smallColumnIndexes: [0],
    sortingOrder: GLAccountSortingInitialData,
    searchKeyColumn: 'glAccount'
  },
  [MaintenanceOptionsEnum.INSTRUMENT_TYPE]: {
    updateData: (data: IInstrumentTitle[]): any => {
      return data
    },
    tableHeader: instrumentTitleDataHeadings,
    smallColumnIndexes: [0, 2],
    sortingOrder: InstrumentTitleSortingOrderInitialData,
    initialSortColumn: 'instrumentType',
    searchKeyColumn: 'instrumentType'
  },
  [MaintenanceOptionsEnum.ORDER_PROJECT]: {
    updateData: (data: IOrderProject): any => {
      return data
    },
    tableHeader: orderProjectDataHeading,
    initialSortColumn: 'orderNumber',
    sortingOrder: orderSortingInitialData,
    smallColumnIndexes: [0],
    searchKeyColumn: 'orderNumber'
  },
  [MaintenanceOptionsEnum.PLANT_LOCATION]: {
    updateData: (data: IPlanLoc[]): any => {
      return data
    },
    tableHeader: plDataHeadings,
    initialSortColumn: 'plantLocation',
    sortingOrder: plSortingInitialData,
    smallColumnIndexes: [0],
    searchKeyColumn: 'plantLocation'
  }
}

import React, { type ChangeEventHandler, type ReactElement } from 'react'
import './CheckBox.scss'

interface ICheckBoxProps {
  id: string
  name: string
  value: string | number
  label?: string
  className?: string
  checked?: boolean
  onChange: ChangeEventHandler<HTMLInputElement>
  readonly?: boolean
  disabled?: boolean
}

const CheckBox = (props: ICheckBoxProps): ReactElement => {
  return (
    <span className='checkbox-button'>
      <input
      name={props.name}
      type="checkbox"
      id={props.id}
      value={props.value}
      checked={props.checked}
      className={`${props.className ? props.className : ''}`}
      onChange={props.onChange}
      disabled={props.disabled}
      />
      {props.label && <label htmlFor={props.id}>{props.label}</label>}
    </span>
  )
}

export default CheckBox

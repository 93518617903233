import React, { useEffect, useState } from 'react'
import Button from '../../components/sharedModules/button/Button'
import { SORT_DIRECTION, navigationPath } from '../../types/CommonTypes'
import { Link, useLocation, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getPropertyList } from '../../store/middleWares/getPropertyList'
import { type AppDispatch } from '../../store/store'
import { type IProperty, type IPropertyListType, setSorting, type PropertySortOrderObjectType, resetSortingOrder } from '../../store/slices/propertySlice'
import Table from '../../components/sharedModules/table/Table'
import Pagination from '../../components/sharedModules/pagination/Pagination'
import './Properties.scss'
import { propertyListHeadings } from '../../components/sharedModules/table/TableHeadingMapping'
import TableRowCount from '../../components/sharedModules/tableRowCount/TableRowCount'
import { setDecimalPrecision } from '../../util/setDecimalPrecision'
import { getParamsBasedonURL } from '../../util/getTableParamsBasedonURL'
import { MONEY } from '../../Constants'
import UnauthorizedWarningPage from '../common/UnauthorizedWarningPage'

const Properties: React.FC = () => {
  const [propertyData, setData] = useState<IPropertyListType[]>([])

  const isAuthorized = useSelector(
    (state: any) => state.auth.isAuthorized
  )
  const dispatch = useDispatch<AppDispatch>()
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams(location.search)

  const { propertyList, pageCount, sortingOrder, totalProperties }: IProperty = useSelector(
    (state: any) => state.property
  )

  const tableNavigationLinks = [{
    path: navigationPath.PROPERTIES + '/',
    linkKey: 'propNo',
    linkIndex: 2
  }, {
    path: navigationPath.VIEW_PROPERTY_INVENTORY_DETAIL + '/',
    linkKey: 'refId',
    linkIndex: 3
  }]

  useEffect(() => {
    const params = getParamsBasedonURL(searchParams)
    if (!params.sort || !params.dir || (params.dir !== SORT_DIRECTION.ASC && params.dir !== SORT_DIRECTION.DSC)) {
      dispatch(resetSortingOrder())
    }
    dispatch(getPropertyList(params))
  }, [location.search])

  useEffect(() => {
    if (propertyList.length > 0) {
      const data = [...propertyList]
      const formattedData = data.map((item: IPropertyListType) => {
        const newItem = {
          ...item,
          totalAcres: setDecimalPrecision(item.totalAcres, 3),
          totalAmount: setDecimalPrecision(item.totalAmount, 2, MONEY)
        }
        return newItem
      })
      setData(formattedData)
    } else { setData(propertyList) }
  }, [propertyList])

  const doSort = (sortData: Record<string, SORT_DIRECTION>) => {
    const sort = Object.keys(sortData)[0]
    const dir = Object.values(sortData)[0]
    searchParams.set('sort', sort)
    searchParams.set('dir', dir)
    setSearchParams(searchParams)
    dispatch(setSorting(sortData as PropertySortOrderObjectType))
  }

  return (
    <section className="properties-container">
      {
        isAuthorized
          ? <>
      <div className="properties-card">
        <h2>Properties</h2>
        <div className='button-container'>
          <Link to={navigationPath.ADD_PROPERTY}>
            <Button type="confirm" className="add-properties-btn-style">
              Add Property
            </Button>
          </Link>
          <Link to={navigationPath.ADD_TRANSACTION}>
            <Button type="confirm" className="properties-btn-style">
              Add Transaction
            </Button>
          </Link>
        </div>
      </div>
      <TableRowCount count={totalProperties}
        subText="Select a Property Name to view details or add a transaction by
        selecting the button at right."/>
      <Table
        data={propertyData}
        headings={propertyListHeadings}
        sortingOrderObj={sortingOrder}
        onClick={(sortData: Record<string, SORT_DIRECTION>) => { doSort(sortData) }}
        isAllSortingRequired={true}
        navigationLinks={tableNavigationLinks}
        alignRightIndexes={[7, 8]} />
      <Pagination totalPages={pageCount} />
      </>
          : <UnauthorizedWarningPage />

      }
    </section>
  )
}

export default Properties

/* eslint-disable @typescript-eslint/no-confusing-void-expression */
import React, { useEffect, useMemo, useState } from 'react'
import './Reports.scss'
import Button from '../../components/sharedModules/button/Button'
import { useDispatch, useSelector } from 'react-redux'
import { type AppDispatch } from '../../store/store'
import ReportsTable from '../../components/reports/ReportsTable'
import { type IReports, resetReportFields, setExportReportModalVisible } from '../../store/slices/reportsSlice'
import { PREDEFINED_REPORTS_ENUM, reportDataMaster } from '../../components/reports/predefinedReportMapping'
import ResponseModal from '../../components/welcome/ResponseModal'
import ExportReport from '../../components/exportReport/ExportReport'
import { setRouteModalVisibility } from '../../store/slices/routeEmailSlice'
import UnauthorizedWarningPage from '../common/UnauthorizedWarningPage'

const Reports = () => {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  let resetPage = false
  const [selectedParam, setParam] = useState('')
  const selectedReport: PREDEFINED_REPORTS_ENUM = useSelector((state: any) => state.report.selectedReport)
  const param = useSelector((state: any) => {
    return (
      state.report.glAccount || state.report.fullPartialPL ||
      state.report.propertyInventoryDetails)
  })
  const dispatch = useDispatch<AppDispatch>()
  const isAuthorized = useSelector(
    (state: any) => state.auth.isAuthorized
  )
  useEffect(() => {
    setParam(param)
  }, [selectedReport])

  useEffect(() => {
    return () => {
      dispatch(resetReportFields())
    }
  }, [])

  const onExportHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    dispatch(setExportReportModalVisible(true))
  }

  const onRouteHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    dispatch(setRouteModalVisibility(true))
  }

  const { reportData, varianceLastUpdatedDate, reoReport, resReport }: IReports = useSelector((state: any) => state.report)

  const headerExtension: string = useMemo(() => {
    resetPage = !resetPage
    switch (selectedReport) {
      case PREDEFINED_REPORTS_ENUM.ENTRIES_BY_GL_ACCOUNT:
        return reportData[0]?.glAccount ?? ''
      case PREDEFINED_REPORTS_ENUM.FULL_PARTIAL_LAND_LOCATION:
      case PREDEFINED_REPORTS_ENUM.PROPERTY_INVENTORY_DETAIL:
        return reportData[0]?.plantLoc ?? ''
      case PREDEFINED_REPORTS_ENUM.VARIANCE_BY_PLANT_LOCATION:
        return varianceLastUpdatedDate ? ` ( Last PowerPlan data update - ${varianceLastUpdatedDate} )` : ''
      case PREDEFINED_REPORTS_ENUM.REO_DETAIL_YTD:
        return `${reoReport.startMonthYear} - ${reoReport.endMonthYear}`
      case PREDEFINED_REPORTS_ENUM.REIS_DETAIL_YTD:
        return `${resReport.startMonthYear} - ${resReport.endMonthYear}`

      default:
        return ''
    }
  }, [selectedReport, reportData, varianceLastUpdatedDate])

  return (
    <div className="reports-container">
      {
        isAuthorized
          ? <>
         <div className="reports-heading">
        <h2>{`${reportDataMaster[selectedReport]?.header} ${headerExtension}`}</h2>
        <section>
          <Button type="confirm" className="route-button"
            onClick={onRouteHandler}>
            Route
          </Button>
          <Button type="confirm" onClick={onExportHandler}>Export</Button>
        </section>
        <ExportReport />
      </div>
      <div className='reports-table-row-container'>
        <ResponseModal />
        <ReportsTable resetPage = {resetPage}/>
      </div>

        </>
          : <UnauthorizedWarningPage/>
      }
         </div>
  )
}

export default Reports

import { PREDEFINED_REPORTS_ENUM } from './components/reports/predefinedReportMapping'
import { SORT_DIRECTION } from './types/CommonTypes'

// ENV variables and constants
export const WELCOME_HEADER = 'Welcome to REIS Land Management'
export const WELCOME_CONTENT = 'Select from the navigation, recent trasactions list or search by Serial #, Document #, APN #, Property Name, County and more to get started.'
export const WELCOME_POPUP_LOCAL_STORAGE_KEY = 'WELCOMEPOPOP'
export const ADD_TRANSACTION_MODAL_HEADER = 'What kind of transaction is this? (select one)'
export const AUTO_SAVE_INFO_MESSAGE = '* this page will automatically save your content'
export const ROUTING_MODAL_HEADER = 'Routing Information'
export const REPORTS_PAGE_TABLE_SUBHEADING = 'Choose preset options or customize by selecting the filter icon'
export const SUCCESSFUL_REPORT_ROUTE_MESSAGE = 'This report has been successfully routed'
export const PROPERTY_ACTIVITY_DATA_ACQUISITION = 'Property Activity Acquisition Data'
export const PROPERTY_ACTIVITY_DATA_DISPOSITION = 'Property Activity Accounting Data'
export const DROPDOWN_API_CALL_DELAY = 300
export const SEARCH_API_CALL_DELAY = 300
export const ACQUISITION = 'Acquisition'
export const AMENDMENT = 'Amendment'
export const RELINQUISH = 'Relinquish'
export const PROPERTY_DATA_AMOUNT = 'amount'
export const PROPERTY_DATA_ACRES = 'acres'
export const RESULT_INITAL_PAGE = 1
export const RESULT_PER_PAGE_PAGINATION = 100
export const RESULT_PER_PAGE_PAGINATION_MAINTENANCE = 20
export const EXCEL_REPORT_FILE_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
export const PDF_REPORT_FILE_TYPE = 'application/pdf'
export const DEFAULT_SORTING_ORDER = SORT_DIRECTION.DSC
export const INITIAL_SORTING_ORDER = SORT_DIRECTION.ASC
export const DEFAULT_PROPERTIES_SORT_COLUMN = 'propNo'
export const DEFAULT_TRANSACTION_SORT_COLUMN = 'recordNo'
export const DEFAULT_INVENTORY_REF_COLUMN = 'acqDocNo'
export const DEFAULT_TRANSACTION_INFO_COOLUMN = 'recordNo'
export const initialReport = PREDEFINED_REPORTS_ENUM.PROPERTY_LIST
export const NO_RESULT = 'No data found with your search criteria'
export const TRY_AGAIN_LABEL = 'Please try again with another search criteria'
export const UNAUTHORISED = 'You have no permission to view this page.'
export const UNAUTHORISED_MESSAGE = 'You have no permission to perform the action.'

// Report Default Sowrt columns are configured in reportSortingOrder.ts file

// API Config
export const GET_PROPERTIES_ENDPOINT = 'properties'
export const GET_TRANSACTION_ENDPOINT = 'transactions'
export const GET_DROPDOWN_VALUE_ENDPOINT = 'dropdown-values'
export const GET_DROPDOWN_THRESHOLD = 'getThreshold'
export const GET_RECORD_NO = 'transactions/get-recordno'
export const DELETE_TRANSACTION = 'transactions/delete-transaction'
export const DELETE_PROPERTY = `${GET_PROPERTIES_ENDPOINT}/delete-property`
export const GET_PDF_ENDPOINT = 'create-pdf'
export const ADD_TRANSACTION_ENDPOINT = `${GET_TRANSACTION_ENDPOINT}/add-transaction`
export const AUTOSAVE_ADD_TRANSACTION_ENDPOINT = `${ADD_TRANSACTION_ENDPOINT}/autosave`
export const GET_PROPERTY_ID = `${GET_PROPERTIES_ENDPOINT}/getPropertyId`
export const GET_REPORT = 'reports'
export const SEND_REPORT = 'reports/route'
export const DOWNLOAD_REPORT = `${GET_REPORT}/download`
export const SEARCH = '/search'
export const GET_PROPERTY_INVENTORY_DATA = 'properties/inventory'
export const GET_MAINTENANCE = 'maintenance'
export const GET_MAINTENANCE_EXPORT = 'maintenance/export'
export const EDMRM_ENDPOINT = 'edmrm'
export const UPLOAD_ATTACHMENTS = `${EDMRM_ENDPOINT}/upload`
export const ADD_PROPERTY_ENDPOINT = `${GET_PROPERTIES_ENDPOINT}/add-property`

// Add transaction Initial data
export const TRANSACTION_STATUS_PREPOPULATE_DATA = 'New'
export const GRANTOR_PREPOPULATE_DATA = 'Southern California Edison'
export const GTANTEE_PREPOPULATE_DATA = 'Southern California Edison'
export const ORGANIZATION_PREPOPULATE_DATA = 'REO'
export const DTYPE_ACQUISITION_PREPOPULATE_DATA = 'New'
export const DTYPE_DISPOSITION_PREPOPULATE_DATA = 'Attached'
export const TTYPE_DISPOSITION_PREPOPULATE_DATA = 'Relinquish'
export const TTYPE_ACQUISITION_PREPOPULATE_DATA_AMENDMENT = 'Amendment'
export const TTYPE_ACQUISITION_PREPOPULATE_DATA = 'Acquisition'
export const MONEY = 'money'

// Add transaction error text
export const ERROR_TEXTS =
{
  INVALID_MONTH: 'Invalid Month',
  INVALID_YEAR: 'Invalid Year',
  REQUIRED_LABEL: 'This field is required',
  RECORD_NO_IS_REQUIRED: 'Record Number is required',
  PROPERTY_IS_REQUIRED: 'Property is required',
  PROPERTY_ID_IS_REQUIRED: 'Property ID is required',
  DOCUMENT_NO_IS_REQUIRED: 'Document Number is required',
  DOCUMENT_TYPE_IS_REQUIRED: 'Document Type is required',
  PREPARED_BY_IS_REQUIRED: 'Prepared by is required',
  PREPARED_DATE_IS_REQUIRED: 'Prepared date is required',
  STATUS_DATE_IS_REQUIRED: 'Status date is required',
  TRANSACTION_TYPE_IS_REQUIRED: 'Transaction type is required',
  GRANTOR_IS_REQUIRED: 'Grantor is required',
  GRANTEE_IS_REQUIRED: 'Grantee is required',
  AGENT_IS_REQUIRED: 'Agent is required',
  WO_IS_REQUIRED: 'Work Order is required',
  INSTRUMENT_TITLE_IS_REQUIRED: 'Instrument Title is required',
  SERIAL_NUMBER_IS_REQUIRED: 'Serial Number is required',
  EXECUTION_DATE_IS_REQUIRED: 'Execution Date is required',
  RECORDED_DATE_IS_REQUIRED: 'Recorded Date is required',
  RECORDERS_NUMBER_IS_REQUIRED: 'Recording Instrument Number is required',
  COUNTY_IS_REQUIRED: 'County is required',
  PLANT_LOC_IS_REQUIRED: 'Plant Loc is required',
  PARCEL_IS_REQUIRED: 'Parcel is required',
  GL_ACCT_REQUIRED: 'GL Acct is required',
  ACQ_DOC_IS_REQUIRED: 'Acq Doc is required',
  ACQ_DOC_LENGTH_CHECK: 'Acq Doc number should not exceed 6 characters',
  FERC_ACCT_IS_REQUIRED: 'FERC Acct is required',
  XFER_DATE_IS_REQUIRED: 'Xfer Date is required',
  FEE_EASE_IS_REQUIRED: 'Fee/Ease is required',
  ACRES_IS_REQUIRED: 'Acres is required',
  AMOUNT_IS_REQUIRED: 'Amount is required',
  APN_IS_REQUIRED: 'Accessor Parcel Number is required',
  DEFAULT_ERROR: 'Please complete all the required fields',
  TOTAL_CONSIDERATION_IS_REQUIRED: 'Total consideration is required',
  BOOK_IS_REQUIRED: 'Book is required',
  SAP_ACTUAL_TOTAL_IS_REQUIRED: 'SAP actual total/labor is required',
  EDMRM_WORKORDER_IS_REQUIRED: 'Please Enter Work order number to upload documents to EDMRM'
}

export const POST_CONFIRM_MESSAGE = 'The status will be changed to POST! Do you want to continue?'
export const BOOKED_CONFIRM_MESSAGE = 'This operation will POST the transaction and change the status to BOOKED! Do you want to continue?'
export const UPDATE_BOOKED_CONFIRM_MESSAGE = 'Are you sure you want to update the information on this transaction?'
export const DELETE_MODAL_TEXT = 'Are you certain you want to delete this transaction?\n(Please note that this action cannot be undone)'
export const DELETE_PROPERTY_MODAL_TEXT = 'Are you certain you want to delete this property?\n(Please note that this action cannot be undone)'
export const UPDATE_PROPERTY_MODAL_TEXT = 'Are you sure you want to update the information on this property?'
export const DELETE_TRANSACTION_WARNING = 'Please note that once a delete transaction is executed, it cannot be undone.'
export const DELETE_MAINTENANCE_WARNING = 'Please note that once a delete is executed, it cannot be undone.'
export enum UPDATE_DELETE_ENUM {
  UPDATE = 'Update-transaction',
  DELETE = 'Delete-transaction'
}
export enum PROPERTY_UPDATE_DELETE_ENUM {
  UPDATE = 'Update-property',
  DELETE = 'Delete-property'
}

export enum MAINTENANCE_UPDATE_DELETE_ENUM {
  UPDATE = 'Update-maintenance',
  DELETE = 'Delete-maintenance'
}

import React, { type ReactElement, useRef } from 'react'
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader
} from '../sharedModules/modal/Modal'
import { ROUTING_MODAL_HEADER, SUCCESSFUL_REPORT_ROUTE_MESSAGE } from '../../Constants'
import './RouteEmail.scss'
import TextArea from '../sharedModules/textArea/TextArea'
import Button from '../sharedModules/button/Button'
import { useSelector, useDispatch } from 'react-redux'
import {
  setRouteModalVisibility, setRouteSuccesfull
} from '../../store/slices/routeEmailSlice'
import { ReactComponent as SuccessSVG } from '../../assets/Success.svg'
import { ReactComponent as ErrorSVG } from '../../assets/ErrorIcon.svg'
import { sendReport } from '../../store/middleWares/sendReports'
import { type AppDispatch } from '../../store/store'
import { type IStatusResponse } from '../../store/slices/viewPropertyInventorySlice'
// import { StatusTypesEnum } from '../../types/CommonTypes'

export const RouteEmail = (): ReactElement | null => {
  const dispatch = useDispatch<AppDispatch>()
  const emailTextAreaRef = useRef<HTMLTextAreaElement>(null)

  const isModalOpen = useSelector((state: any) => state.routeEmail.isModalOpen)
  const isRouteSuccessFull: boolean | undefined = useSelector((state: any) => state.routeEmail.isRouteSuccessFull)
  const status: IStatusResponse = useSelector((state: any) => state.routeEmail.status)

  if (!isModalOpen) return null

  const onCloseHandler = () => {
    dispatch(setRouteModalVisibility(false))
  }

  const onSuccessFullRouteCloseHandler = () => {
    dispatch(setRouteSuccesfull(undefined))
    onCloseHandler()
  }

  const onConfirmHandler = () => {
    const receiptients = emailTextAreaRef.current?.value.trim()
    if (!receiptients) return null
    const receiptientList = receiptients.split(',')
    dispatch(sendReport(receiptientList))
    dispatch(setRouteModalVisibility(false))
  }

  const responseComponent = () => {
    if (isRouteSuccessFull === true) {
      return (
        <div className="successfull-route-message">
          <SuccessSVG />
          <p>{SUCCESSFUL_REPORT_ROUTE_MESSAGE}</p>
        </div>
      )
    } else if (isRouteSuccessFull === false) {
      return (
        <div className="successfull-route-message">
          <div>
            <ErrorSVG />
          </div>
          <p>{status.message}</p>
        </div>
      )
    } else {
      return (
        <>
          <label>Route this report to the following email(s):</label>
          <TextArea
            name="route-recieptients"
            placeholder="Email@sce.com, Email@sce.com, etc."
            ref={emailTextAreaRef}
          />
        </>
      )
    }
  }

  return (
    <Modal className="route-email-modal">
      <ModalHeader>
        <section className="route-modal-header">
          <h4>{ROUTING_MODAL_HEADER}</h4>
        </section>
      </ModalHeader>
      <ModalBody>
        <div className="route-modal-body">
          {/* <div className="successfull-route-message"> */}
          {responseComponent()}
          {/* </div> */}
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="route-modal-footer">
          {isRouteSuccessFull !== undefined
            ? (
              <>
                <Button type="confirm" onClick={onSuccessFullRouteCloseHandler}>
                  Close
                </Button>
              </>
              )
            : (
              <>
                {' '}
                <Button type="cancel" onClick={onCloseHandler}>
                  Cancel
                </Button>
                <Button type="confirm" onClick={onConfirmHandler}>
                  Continue
                </Button>
              </>
              )}
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default RouteEmail

/* eslint-disable @typescript-eslint/no-confusing-void-expression */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { login } from '../../config/tokenConfig'
import { useNavigate } from 'react-router-dom'
import { navigationPath } from '../../types/CommonTypes'

const Login: React.FC = () => {
  const { instance, accounts } = useMsal()
  const isAuthenticated = useIsAuthenticated()
  const navigate = useNavigate()

  useEffect(() => {
    login()
    if (isAuthenticated && accounts.length > 0) {
      navigate(navigationPath.HOME)
    }
  }, [accounts, instance, isAuthenticated])

  return <div></div>
}

export default Login

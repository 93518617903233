export const replaceNonDigit = (input: string): string => {
  const allowedCharacters = '0123456789'
  let convertedStr = ''
  for (let i = 0; i < input.length; i++) {
    if (allowedCharacters.includes(input[i])) {
      convertedStr += input[i]
    }
  }
  return convertedStr
}

export const replaceAlphabets = (input: string): boolean => {
  if (input === '') return true
  const isValid = /^[\d\W]+$/.test(input)
  return isValid
}

export const sanitizeGlAct = (input: string): string => {
  const allowedCharacters = '0123456789.'
  let convertedStr = ''
  for (let i = 0; i < input.length; i++) {
    if (allowedCharacters.includes(input[i])) {
      convertedStr += input[i]
    }
  }
  return convertedStr
}

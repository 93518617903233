import React, { type ReactElement, useEffect, useRef } from 'react'
import Select from '../../../sharedModules/select/Select'
import Input from '../../../sharedModules/input/Input'
import { ReactComponent as DeleteSVG } from '../../../../assets/Delete.svg'
import { useDispatch, useSelector } from 'react-redux'
import { getDropdownData } from '../../../../util/getDropDownPropData'
import { type ICrossInformation } from '../../../../types/TransactionTypes'
import { setCrossReferenceData } from '../../../../store/slices/addTransactionSlice'
import useDebounce from '../../../../customHooks/useDebounce'
import { getDropdownFilteredValues } from '../../../../store/middleWares/getDropdownValues'
import { DROPDOWN_API_CALL_DELAY } from '../../../../Constants'
import { DropDownFiledsEnum } from '../../../../types/CommonTypes'
import { type AppDispatch } from '../../../../store/store'
import { replaceNonDigit } from '../../../../util/sanitizeString'
import { type IViewCrossReference, setCrossReferenceDataView } from '../../../../store/slices/viewTransactionSlice'

const CrossReferenceInformationSub = (props: {
  id: number
  view?: boolean
  isBookedUpdatable?: boolean
  removeHandler?: (id: any) => any
}): ReactElement => {
  const id = props.id - 1
  const dispatch = useDispatch<AppDispatch>()
  const documentType = useSelector((state: any) => state.dropDown.documentType)

  const fieldData: Array<ICrossInformation | IViewCrossReference> = useSelector(
    (state: any) => props.view ? state.viewTransaction.crossReference : state.addTransaction.crossReference
  )
  const fieldDataRef = useRef(fieldData)

  useEffect(() => {
    fieldDataRef.current = fieldData
  }, [fieldData])

  const isBookedTransaction: boolean | false = useSelector((state: any) => {
    return props.view
      ? state.viewTransaction.isBookedTransaction
      : state.addTransaction.isBookedTransaction
  })

  const onRemove = () => {
    props.removeHandler!(props.id)
    dispatch(props.view ? setCrossReferenceDataView({ index: id, data: null }) : setCrossReferenceData({ index: id, data: null }))
  }

  const onFieldValueChangeHandler = (data: any) => {
    if (data.documentRef) {
      const convertedStr = replaceNonDigit(data.documentRef)
      data.documentRef = convertedStr
    }
    dispatch(props.view ? setCrossReferenceDataView({ index: id, data: { ...fieldDataRef.current[id], ...data } }) : setCrossReferenceData({ index: id, data: { ...fieldDataRef.current[id], ...data } }))
  }

  const getDropdownValuesWithoutDelay = (field: string, value: string) => {
    dispatch(getDropdownFilteredValues({ field, token: value }))
  }

  const debounceAPICall = useDebounce((field: string, value: string) => {
    dispatch(getDropdownFilteredValues({ field, token: value }))
  }, DROPDOWN_API_CALL_DELAY)

  const onDropdownValueChangeHanlder = (data: any, field: string) => {
    onFieldValueChangeHandler(data)
    const value: string = Object.values(data)[0] as string
    debounceAPICall(field, value)
  }

  const isBooked = () => {
    return props.view && isBookedTransaction && !props.isBookedUpdatable
  }

  return (
    <div className="sub-section" key={props.id}>
      <section className='dropdown-section'>
        <label className="input-label">Document Type</label>
        <Select
          name={`document_select${props.id}`}
          value={fieldData[id]?.documentType ?? ''}
          data={getDropdownData(documentType)}
          onChange={(selectedItem: string) => { onDropdownValueChangeHanlder({ documentType: selectedItem }, DropDownFiledsEnum.DOCUMENT_TYPE) }}
          triggerOnFocus={(value: string) => { getDropdownValuesWithoutDelay(DropDownFiledsEnum.DOCUMENT_TYPE, value) }}
          readonly={isBooked()}
          placeholder={isBooked() ? '' : 'Select an option'}
          className={isBooked() ? 'read-input' : ''}
        />
      </section>
      <section>
        <label className="input-label">Document Reference</label>
        <Input
          name={`document-reference${props.id}`}
          value={fieldData[id]?.documentRef ?? ''}
          onChange={(e) => { onFieldValueChangeHandler({ documentRef: e.target.value?.substring(0, 50) }) }}
          readOnly={isBooked()}
          className={isBooked() ? 'read-input' : ''}
          placeholder={isBooked() ? '' : 'Placeholder text'}
          inputType="string"
        ></Input>
      </section>
      {(props.removeHandler != null) && (!isBookedTransaction || props.isBookedUpdatable) && (
        <div
          className="delete-row-icon-container"
          onClick={() => { onRemove() }}
        >
          <DeleteSVG />
        </div>
      )}
    </div>
  )
}

export default CrossReferenceInformationSub

import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import { getPropertyList } from '../middleWares/getPropertyList'
import { SORT_DIRECTION, StatusTypesEnum } from '../../types/CommonTypes'
import { type IStatusResponse } from './viewPropertyInventorySlice'
import { DEFAULT_SORTING_ORDER } from '../../Constants'

export interface IPropertyListType {
  refId: string
  acqDocNo: string
  parcelNo: string
  serialNumber: string
  totalAmount: string
  totalAcres: string
  propNo: string
  propId: string
  propertyName: string
  propertyType: string
  propertyCounty: string
  propertyCity: string
  propertyAddress: string
}

export type PropertySortOrderObjectType = {
  [k in keyof IPropertyListType]: SORT_DIRECTION
}

export interface IProperty {
  propertyList: IPropertyListType[]
  pageCount: number
  sortingOrder: PropertySortOrderObjectType
  status: IStatusResponse
  totalProperties: number
}

const sortingOrderInitialData: PropertySortOrderObjectType = {
  refId: DEFAULT_SORTING_ORDER,
  acqDocNo: DEFAULT_SORTING_ORDER,
  parcelNo: DEFAULT_SORTING_ORDER,
  serialNumber: DEFAULT_SORTING_ORDER,
  totalAmount: DEFAULT_SORTING_ORDER,
  totalAcres: DEFAULT_SORTING_ORDER,
  propNo: DEFAULT_SORTING_ORDER,
  propId: DEFAULT_SORTING_ORDER,
  propertyName: DEFAULT_SORTING_ORDER,
  propertyType: DEFAULT_SORTING_ORDER,
  propertyCounty: DEFAULT_SORTING_ORDER,
  propertyCity: DEFAULT_SORTING_ORDER,
  propertyAddress: DEFAULT_SORTING_ORDER
}

export const propertyInitialData: IProperty = {
  propertyList: [],
  pageCount: 0,
  sortingOrder: { ...sortingOrderInitialData },
  status: { type: undefined, message: '' },
  totalProperties: 0
}

const propertySlice = createSlice({
  name: 'property',
  initialState: propertyInitialData,
  reducers: {
    setSorting (state, action: PayloadAction<PropertySortOrderObjectType>) {
      const [[columnName, sortOrder]] = Object.entries(action.payload)
      const updatedSortOrder = sortOrder === SORT_DIRECTION.ASC ? SORT_DIRECTION.DSC : SORT_DIRECTION.ASC
      state.sortingOrder = { ...sortingOrderInitialData, [columnName]: updatedSortOrder }
    },
    resetSortingOrder (state) {
      state.sortingOrder = { ...sortingOrderInitialData }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getPropertyList.pending, (state) => {
      state.status.type = StatusTypesEnum.LOADING
      state.status.message = propertyInitialData.status.message
    })

    builder.addCase(getPropertyList.fulfilled, (state, action) => {
      if (action.payload.data) {
        state.propertyList = action.payload.data
      } else {
        state.propertyList = []
      }
      state.pageCount = action.payload.totalPages
      state.status.type = StatusTypesEnum.SUCCESS
      state.status.message = propertyInitialData.status.message
      state.totalProperties = action.payload.totalResult
    })

    // TODO: Error handling
    builder.addCase(getPropertyList.rejected, (state, action) => {
      state.status.type = StatusTypesEnum.ERROR
      state.status.message = action.error.message
    })
  }
})

export const { setSorting, resetSortingOrder } = propertySlice.actions
export default propertySlice.reducer

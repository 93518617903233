import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { type AppDispatch } from '../../../store/store'
import { useParams } from 'react-router'
import Button from '../../../components/sharedModules/button/Button'
import InventoryInfo from '../../../components/propertyInventory/inventoryInfo/InventoryInfo'
import { getPropertyInventoryByAcqDocNo } from '../../../store/middleWares/getPropertyInventory'
import { resetErrorValue, resetPropertyInventoryInfo } from '../../../store/slices/viewPropertyInventorySlice'
import { Link, useNavigate } from 'react-router-dom'
import InventoryAcctData from '../../../components/propertyInventory/inventoryAcctData/InventoryAcctData'
import scrollToTop from '../../../util/scrollToTop'
import './Inventory.scss'
import { updateInventory } from '../../../store/middleWares/updateInventory'
import ResponseModal from '../../../components/welcome/ResponseModal'
import { navigationPath, PDF_TYPES } from '../../../types/CommonTypes'
import PdfModal from '../../../components/pdfGenerator/pdfModal/PdfModal'
import NotFound404Page from '../../common/NotFound404'
import { type IErrorData } from '../../../types/TransactionTypes'
import ErrorModal from '../../../components/welcome/ErrorModal'

const Inventory: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()

  const { id } = useParams()
  const [viewPdf, setViewPdf] = useState<boolean>(false)
  const [isSaveButtonActive, setSaveButtonActive] = useState<boolean>(false)
  // const [isSaveButtonClicked, setSaveButtonClicked] = useState<boolean>(false)

  useEffect(() => {
    if (id !== 'error') {
      dispatch(getPropertyInventoryByAcqDocNo(id))
      scrollToTop()
    }
    return () => {
      dispatch(resetPropertyInventoryInfo())
    }
  }, [id, dispatch])

  const errorData: IErrorData = useSelector(
    (state: any) => {
      return state.viewPropertyInventory.errorData
    }
  )

  // useEffect(() => {
  //   if (isSaveButtonClicked) {
  //     setSaveButtonClicked(false)
  //     if (!errorData.isError) {
  //       dispatch(updateInventory(id))
  //     }
  //   }
  // }, [isSaveButtonClicked])

  const { comments } = useSelector((state: any) => state.viewPropertyInventory)

  const onCancelHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    dispatch(resetPropertyInventoryInfo())
    navigate(-1)
  }

  const onSave = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    // dispatch(doValidationInventoryOnSave(true))
    dispatch(updateInventory(id))
    // setSaveButtonClicked(true)
  }

  const handlePdfgeneration = (event: React.MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault()
    setViewPdf(true)
  }

  const handleFormChange = () => {
    setSaveButtonActive(true)
  }

  const errorModalCallBackFunc = () => {
    // setSaveButtonClicked(false)
    setSaveButtonActive(true)
  }

  if (id === 'error') return <NotFound404Page />
  return (
    <>
      <ResponseModal />
      <section>
        <InventoryInfo />
        <InventoryAcctData onChange={handleFormChange}/>
        <ErrorModal defaultErr={errorData.defaultErr}
          text={Object.values(errorData.errorDesc)}
          show={errorData.isError}
          callBack={errorModalCallBackFunc}
          resetFn={resetErrorValue}
        />
        <div className="add-transaction-footer">
          <span>
            <Button onClick={onCancelHandler} type="cancel">
              Cancel
            </Button>
          </span>
          <span className="add-transaction-button-group">
            <Link to={navigationPath.ADD_TRANSACTION}>
              <Button
                className="route-button"
                type="confirm"
              >
                Add Transaction
              </Button>
            </Link>
            <Button
              onClick={onSave}
              className={(comments || isSaveButtonActive) ? 'save-create-pdf-button' : 'print-button'}
              type="confirm"
            >
              Save
            </Button>
            <Button className="save-create-pdf-button" type="confirm" onClick={handlePdfgeneration}>
              Create .pdf
            </Button>
          </span>
        </div>
      </section>
      {viewPdf && (
        <>
          <PdfModal onClose={setViewPdf} className='transaction-pdf-modal' pdfType={PDF_TYPES.PROPERTY_INVENTORY_PDF}/>
        </>)}
    </>
  )
}

export default Inventory

import React, { type ReactElement, useEffect } from 'react'
import TableRowCount from '../sharedModules/tableRowCount/TableRowCount'
import Table from '../sharedModules/table/Table'
import { MONEY, REPORTS_PAGE_TABLE_SUBHEADING } from '../../Constants'
import { useDispatch, useSelector } from 'react-redux'
import Pagination from '../sharedModules/pagination/Pagination'
import { getReport } from '../../store/middleWares/getReport'
import { type AppDispatch } from '../../store/store'
import { type IReports, setSorting, setPredefinedReport, resetSortingOrder } from '../../store/slices/reportsSlice'
import { SORT_DIRECTION } from '../../types/CommonTypes'
import { PREDEFINED_REPORTS_ENUM, reportDataMaster } from './predefinedReportMapping'
import './ReportsTable.scss'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import { getParamsBasedonURL } from '../../util/getTableParamsBasedonURL'
import useNavigateBasedOnReports from '../../customHooks/useNavigateBasedOnReports'
import { setDecimalPrecision } from '../../util/setDecimalPrecision'

const ReportsTable = (props: any): ReactElement => {
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams(location.search)
  const { reportName } = useParams()
  const { navigateTo, updateParamsBasedOnURL } = useNavigateBasedOnReports()
  const dispatch = useDispatch<AppDispatch>()
  const {
    reportData
    , selectedReport
    , workAllocationReport1
    , workAllocationReport2
    , varianceByPlantLocation
    , glAccount
    , rangeOfPlantLocation
    , fullPartialPL
    , propertyInventoryDetails
    , resReport
    , reoReport
    , sortingOrder
    , pageCount
    , totalRows
  }: IReports = useSelector((state: any) => state.report)

  const currentReportMappingData = reportDataMaster[selectedReport]
  const params = {
    glAccount,
    workAllocationReport1,
    varianceByPlantLocation,
    workAllocationReport2,
    rangeOfPlantLocation,
    fullPartialPL,
    propertyInventoryDetails,
    resReport,
    reoReport
  }

  useEffect(() => {
    const defaultParams = getParamsBasedonURL(searchParams)
    const updatedParams = updateParamsBasedOnURL(params, selectedReport)
    if (!defaultParams.sort || !defaultParams.dir || (defaultParams.dir !== SORT_DIRECTION.ASC && defaultParams.dir !== SORT_DIRECTION.DSC)) {
      dispatch(resetSortingOrder())
    }
    if (!reportName || reportName === selectedReport) dispatch(getReport({ selectedReport, param: updatedParams, defaultParams }))
  }, [location.search, selectedReport])

  useEffect(() => {
    const updatedReportName = reportName as PREDEFINED_REPORTS_ENUM
    if (reportName !== selectedReport && reportName) {
      dispatch(setPredefinedReport(updatedReportName))
    }
  }, [location.pathname, reportName])

  const doSort = (sortData: Record<string, SORT_DIRECTION>) => {
    const sort = Object.keys(sortData)[0]
    const dir = Object.values(sortData)[0]
    searchParams.set('sort', sort)
    searchParams.set('dir', dir)
    setSearchParams(searchParams)
    const defaultParams = getParamsBasedonURL(searchParams)
    navigateTo(selectedReport, params, defaultParams)
    dispatch(setSorting(sortData))
  }

  const workOrderReportSection = () => {
    return (
      <div className = 'sub-description'>
        <div className='total-cost-container'>
        <div className='total-cost-div'>
          <label className='total-cost-label'>Total Costs:</label>
          <label className='total-cost-label-right'>{setDecimalPrecision(reportData[0]?.totalCostPerPdf, 2, MONEY)}</label>
          </div>
          <div className='total-cost-div'>
          <label className='total-cost-label'>Total Consideration:</label>
          <label className='total-cost-label-right'>{setDecimalPrecision(reportData[0]?.totalConsiderationPerPdf, 2, MONEY)}</label>
          </div>
          <div className='total-cost-div'>
          <label className='total-cost-label'>Total Allocation:</label>
          <label className='total-cost-label-right'>{setDecimalPrecision(reportData[0]?.totalAllocationPerPdf, 2, MONEY)}</label>
          </div>
        </div>
        <div className='work-order-container'>
          <div className='work-order-div'>
          <label className='work-order-label-bold'>Work Order:</label>
          <label className='work-order-label'>{reportData[0]?.workOrderNumber}</label>
          </div>
          <div className='work-order-div'>
          <label className='work-order-label-bold'>Project:</label>
          <label className='work-order-label'>{reportData[0]?.projectName}</label>
          </div>
          <div className='work-order-div'>
          <label className='work-order-label-bold'>Property Name:</label>
          <label className='work-order-label'>{reportData[0]?.propertyName}</label>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <TableRowCount
        filterIcon={true}
        count={totalRows}
        subText={REPORTS_PAGE_TABLE_SUBHEADING}
      />
      {
        (selectedReport === PREDEFINED_REPORTS_ENUM.WO_ALLOCATION_REPORT1 ||
        selectedReport === PREDEFINED_REPORTS_ENUM.WO_ALLOCATION_REPORT2) &&
        workOrderReportSection()
      }
      <Table
        data={reportData}
        headings={currentReportMappingData?.tableHeader}
        onClick={doSort}
        isAllSortingRequired={true}
        sortingOrderObj={sortingOrder}
        alignRightIndexes={currentReportMappingData?.alignRightIndices}
        />
      {reportData.length > 0 &&
      <Pagination resetPages={props.resetPage} totalPages={pageCount} />
      }
    </>
  )
}

export default ReportsTable

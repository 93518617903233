import { createAsyncThunk } from '@reduxjs/toolkit'
import { MaintenanceOptionsEnum } from '../../components/maintenance/maintenanceOptions'
import api from '../../config/axios.config'
import { GET_MAINTENANCE } from '../../Constants'
import { type IMaintenance } from '../slices/maintenanceSlice'

const getMaintenanceState = (state: any) => {
  return state.maintenance
}

const getIdByCategory = (category: MaintenanceOptionsEnum, data: any) => {
  switch (category) {
    case MaintenanceOptionsEnum.PEOPLE:
      return data.employeeId
    case MaintenanceOptionsEnum.COMPANIES:
      return data.companyId
    case MaintenanceOptionsEnum.DOCUMENT_TYPE:
      return data.docTypeId
    case MaintenanceOptionsEnum.FERC_ACCT:
      return data.fercId
    case MaintenanceOptionsEnum.GL_ACCOUNT:
      return data.glAccountId
    case MaintenanceOptionsEnum.INSTRUMENT_TYPE:
      return data.instrumentType
    case MaintenanceOptionsEnum.ORDER_PROJECT:
      return data.orderId
    case MaintenanceOptionsEnum.PLANT_LOCATION:
      return data.plantLocationId
    case MaintenanceOptionsEnum.ROUTING_ORGANIZATION:
      return data.organizationId
  }
}

export const deleteMaintenanceCategoryData = createAsyncThunk('delete-cat-item',
  async ({ selectedCategory, deletedIndex, defaultParams }: { selectedCategory: MaintenanceOptionsEnum, deletedIndex: number, defaultParams: Record<string, any> },
    { getState, dispatch }) => {
    try {
      const stateData: IMaintenance = getMaintenanceState(getState())
      const deletedData = stateData.maintenanceData[deletedIndex]
      const id = getIdByCategory(selectedCategory, deletedData)

      if (id) {
        const responseData = await api.delete(`/${GET_MAINTENANCE}/${selectedCategory}/${id}`,
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        )
        if (!responseData.data) throw new Error('No response data')
        return responseData.data
      } else {
        // record not yet saved in database
        // const categoryData = [...stateData.maintenanceData]
        // categoryData.splice(deletedIndex, 1)
        // dispatch(setMaintananceData(categoryData))
        return { message: 'Deleted successfully' }
      }
    } catch (err) {
      console.log(JSON.stringify(err), 'delete error')
    }
  })

export const updateMaintenanceCategoryData = createAsyncThunk(
  'update-maintenance', async ({ selectedCategory, defaultParams }:
  { selectedCategory: MaintenanceOptionsEnum, defaultParams: Record<string, any> },
  { rejectWithValue, getState }) => {
    try {
      const stateData: IMaintenance = getMaintenanceState(getState())

      const responseData = await api.post(`/${GET_MAINTENANCE}/${selectedCategory}`,
        {
          updatedData: stateData.maintenanceData,
          // deletedData: stateData.deletedData,
          ...defaultParams
        }
      )
      if (!responseData.data) throw new Error('No response data')
      return responseData.data
    } catch (error: any) {
      // const errorData = error.response.data.message
      throw error?.response?.data
      // return rejectWithValue(Array.isArray(errorData) ? errorData[0] : errorData)
    }
  })

const getCurrentDate = (): string => {
  const date = new Date()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  const formattedDateString = `${date.getFullYear()}-${month}-${day}`
  return formattedDateString
}

export default getCurrentDate

export const formatDate = (dateStr: string): string => {
  if (!dateStr) return ''
  const dt = new Date(dateStr)
  const day = String(dt.getUTCDate()).padStart(2, '0')
  const month = String(dt.getMonth() + 1).padStart(2, '0')
  const yearStr = dt.getFullYear()
  return `${month}/${day}/${yearStr}`
}

export const formatDateForFormData = (dateStr: string): string => {
  const dt = new Date(dateStr)
  const day = String(dt.getUTCDate()).padStart(2, '0')
  const month = String(dt.getUTCMonth() + 1).padStart(2, '0')
  const yearStr = dt.getUTCFullYear()
  return `${month}/${day}/${yearStr}`
}

import React from 'react'
import '../routeOrganizations/RouteOrganizations.scss'
import { useDispatch, useSelector } from 'react-redux'
import { type AppDispatch } from '../../../../store/store'
import { type IDocumentType } from '../../../../types/MaintenanceCategoryTypes'
import { setDeleteItemIndex, setMaintananceData, updateMaintenanceData } from '../../../../store/slices/maintenanceSlice'
import { type SORT_DIRECTION } from '../../../../types/CommonTypes'
import { MaintenanceOptionsEnum, maintenanceDataMaster } from '../../maintenanceOptions'
import MaintenanceCategortHeader from '../maintenanceDataHeader/MaintenanceCategoryHeader'
import Button from '../../../sharedModules/button/Button'
import { ReactComponent as DeleteSVG } from '../../../../assets/Delete.svg'
import Input from '../../../sharedModules/input/Input'

interface ChildProps {
  onChange: () => void
  sort: (sortData: Record<string, SORT_DIRECTION>) => void
  onDelete: (event: React.MouseEvent<SVGSVGElement>) => void
  documentTypes: IDocumentType[]

}

export const DocumentType: React.FC<ChildProps> = ({ sort, onChange, onDelete, documentTypes }) => {
  const dispatch = useDispatch<AppDispatch>()

  const { sortingOrder } = useSelector((state: any) => {
    return state.maintenance
  })
  const handleDeleteByIndex = (event: React.MouseEvent<SVGSVGElement>, index: number) => {
    dispatch(setDeleteItemIndex(index))
    onDelete(event)
  }
  const onAddButtonClickHandler = (): void => {
    const newData: IDocumentType = {
      docTypeId: '',
      docType: '',
      docTypeDescription: ''
    }
    dispatch(setMaintananceData([...documentTypes, newData]))
    onChange()
  }
  const handleInputChange = (e: React.ChangeEvent<HTMLDivElement>) => {
    onChange()
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    e.preventDefault()
    let { value, name } = e.target
    if (name === 'docType') value = value.substring(0, 10)
    if (name === 'docTypeDescription') value = value.substring(0, 50)
    dispatch(updateMaintenanceData({ data: { [e.target.name]: value }, index }))
  }

  const renderComponent = (item: IDocumentType, index: number) => {
    const { docType, docTypeDescription } = item

    return (
            <div className='div-container' key={index}>
                <section className='input-section small'>
                    <Input
                        name='docType'
                        value={docType ?? '' }
                        onChange={(e) => { handleChange(e, index) }}
                    />
                </section>
                <section className='input-section large'>
                    <Input
                        name='docTypeDescription'
                        value={docTypeDescription ?? ''}
                        onChange={(e) => { handleChange(e, index) }}
                    />
                </section>
                <div className='delete-icon'>
                    <DeleteSVG onClick={(e) => {
                      handleDeleteByIndex(e, index)
                    }} />
                </div>
            </div>
    )
  }
  return (
        <div className='container'>
            <div className='Header-container'>
                <label className='heading-label'>Document Types</label>
            </div>
            <MaintenanceCategortHeader sortFn={sort} sortingOrderObj={sortingOrder}
                headerList={maintenanceDataMaster[MaintenanceOptionsEnum.DOCUMENT_TYPE].tableHeader}
                smallColumnIndexes={maintenanceDataMaster[MaintenanceOptionsEnum.DOCUMENT_TYPE].smallColumnIndexes}
                className={'icon'} />
            <div onChange={handleInputChange}>
                {
                    documentTypes?.length > 0 &&
                      documentTypes.map((item, index) => {
                        return renderComponent(item, index)
                      })
                }
            </div>
            <div className="add-another-button-container">
                <Button
                    buttonType="button"
                    className="add-another-button"
                    type="confirm"
                    onClick={onAddButtonClickHandler}
                >
                    +Add Another
                </Button>
            </div>
        </div>

  )
}

import React, { type ReactElement, useRef, useState } from 'react'
import './Welcome.scss'
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader
} from '../sharedModules/modal/Modal'
import { WELCOME_HEADER, WELCOME_CONTENT } from '../../Constants'
import Button from '../sharedModules/button/Button'
import { skipMessageInFuture } from '../../util/skipMessageUtil'

const Welcome = (): ReactElement | null => {
  const [viewModal, setViewModal] = useState<boolean>(true)
  const skipMessageRef = useRef<HTMLInputElement>(null)

  const modalViewHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (skipMessageRef.current?.checked) {
      skipMessageInFuture()
    }
    setViewModal(false)
  }
  if (!viewModal) return null

  return (
    <Modal>
      <ModalHeader>
        <section className="welcome-header">
          <h4>{WELCOME_HEADER}</h4>
        </section>
      </ModalHeader>
      <ModalBody>
        <div>
          <p>{WELCOME_CONTENT}</p>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="welcome-box-footer-container">
          <div className="welcome-box-footer">
            <input type="checkbox" ref={skipMessageRef} />
            <label>skip this message in the future</label>
          </div>
          <Button type="confirm" onClick={modalViewHandler}>
            Continue
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default Welcome

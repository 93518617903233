import React, { useState } from 'react'
import { ReactComponent as FilterIcon } from '../../../assets/FilterIcon.svg'
import PredefinedReports from '../../reports/PredefinedReports'
import './TableRowCount.scss'

interface ITableRowCountProps {
  count: number
  subText: string
  filterIcon?: boolean
}

const TableRowCount = (props: ITableRowCountProps) => {
  const [showPredefinedReportsModal, setShowPredefinedReportsModal] =
    useState<boolean>(false)

  const onFilterButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setShowPredefinedReportsModal((prevState: boolean) => !prevState)
  }

  const onCloseFilterModalhandler = () => {
    setShowPredefinedReportsModal(false)
  }

  return (
    <div className="table-subheading-container">
      {props.filterIcon && <div className='filter-button-container'>
        <button onClick={onFilterButtonClick}>
          <FilterIcon />
        </button>
        <div className='filter-modal-content'>
          {showPredefinedReportsModal && <PredefinedReports onClose={onCloseFilterModalhandler} />}
        </div>
      </div> }
      <section>
        <strong>{`${props.count ?? 0} rows`}</strong>
      </section>
      <p>{props.subText}</p>
    </div>
  )
}

export default TableRowCount

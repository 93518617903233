import React, { useEffect, useState } from 'react'
import './MaintenaceFooter.scss'
import Pagination from '../../../sharedModules/pagination/Pagination'
import { useSelector } from 'react-redux'

const MaintenanceFooter = ({ params }: any) => {
  const [reset, setReset] = useState<any>(undefined)

  const { totalRows, resultPerPage } = useSelector((state: any) => {
    return state.maintenance
  })

  useEffect(() => {
    setReset(JSON.stringify(params))
  }, [JSON.stringify(params)])

  // useEffect(() => {
  //   setReset(resetToFirstPage)
  // }, [resetToFirstPage])

  const pageCount: number = useSelector((state: any) => {
    return state.maintenance.pageCount
  })

  return (
        <div className='footer-container'>
            <div className='text-container'>
            <label className='input-label-optional'><strong>{totalRows} Rows</strong> Total Count</label>
            <div className='pagination-container'>
            <label className='input-label-optional'><strong>{ resultPerPage } Rows</strong> per page</label>
            <Pagination totalPages={pageCount}
            params={params}
            resetPages={reset}
            />
            </div>
            </div>
        </div>
  )
}

export default MaintenanceFooter
